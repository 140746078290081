<div class="contract-row-card card-wrapper">
  <div class="card">
    <div class="head-top">
      <div class="checkbox">
        <mat-checkbox
          #check
          [checked]="params.value"
          (change)="checkedHandler(check.checked)"
          [disabled]="isDisabled" 
          class="example-margin"
        >
          <span class="selected" *ngIf="check.checked" >{{ 'Selected' | translate }}</span>
        </mat-checkbox>
      </div>
    </div>
    <ul>
      <li class="row" *ngFor="let item of itemOutKV">
        <div class="col-2">
          <h5 class="title">{{ item.label | translate }}</h5>
        </div>
        <span>:</span>
        <div class="col-10">
          <data *ngIf="item.label !== 'itemName'; else otherName">
            {{ item.value }}
          </data>

          <ng-template #otherName>
            <data class="special-span" [innerHTML]="item.value | formLineItemName"></data>
          </ng-template>
        </div>
      </li>
    </ul>

    <por-base-button (onclick)="loadRequestmodal()" [featureName]="'item-out-requests-button'">{{
      'Requests' | translate
    }}</por-base-button>
  </div>
</div>
