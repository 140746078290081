<div class="accordion-item" [ngClass]="{ active: opened }">
    <div class="accordion-header" (click)="toggleAccordian()">
        {{ title }}
        <button class="accordion-toggle-btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
            </svg>
        </button>
    </div>
    <div class="body" [ngClass]="{ hidden: !opened }">
        <ng-content></ng-content>
    </div>
    <div></div>
</div>
