import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { catchError, exhaustMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { InvoicesService } from '../../services/invoices.service';
import { fetchInvoices, clearInvoicesinStore, setInvoicesLoading, setNoMoreRecordInvoices, loadInvoicesAction, increasePageNumberInvoices } from '../actions/invoices.actions';
import { getPageNumberInvoices } from '../selector/invoices.selectors';
import { AppFacadeService } from '../../services/app-facade.service';

@Injectable()
export class InvoicesEffects {
    constructor(private readonly actions$: Actions, private readonly invoicesService: InvoicesService, private readonly appFacadeService: AppFacadeService, private readonly store: Store) {}

    fetchInvoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchInvoices),
            tap(() => this.appFacadeService.setLoading(true)),
            withLatestFrom(this.store.pipe(select(getPageNumberInvoices))),
            exhaustMap(([action, pageNumber]) => {
                const { customerId, clearPreviousRecords, startDate, endDate, search } = action;

                // Combine actions to be dispatched in an array
                const actionsToDispatch: Action[] = [];

                if (clearPreviousRecords) {
                    actionsToDispatch.push(clearInvoicesinStore());
                }

                actionsToDispatch.push(setInvoicesLoading({ loading: true }));

                return this.invoicesService.getInvoicesRecords(customerId, pageNumber, startDate, endDate, search).pipe(
                    switchMap(items => {
                        if (items.length <= 0) {
                            actionsToDispatch.push(setNoMoreRecordInvoices({ record: true }));
                        } else {
                            actionsToDispatch.push(setNoMoreRecordInvoices({ record: false }));
                            actionsToDispatch.push(loadInvoicesAction({ invoices: items }));
                            if (!clearPreviousRecords) {
                                actionsToDispatch.push(increasePageNumberInvoices());
                            }
                        }
                        actionsToDispatch.push(setInvoicesLoading({ loading: false }));

                        // Emit all accumulated actions
                        return of(...actionsToDispatch);
                    }),
                    catchError(() => {
                        // Handle the error by dispatching relevant actions
                        return of(setNoMoreRecordInvoices({ record: true }), setInvoicesLoading({ loading: false }));
                    })
                );
            })
        )
    );
}
