<div class="dialog-backdrop">
    <div class="dialog-box">
        <div class="dialog-content">
            <p class="error-message">{{ 'errorMessage' | translate }}</p>
            <button (click)="onRetryClick()">{{ 'Retry' | translate }}</button>

            <div class="error-details">
                <p><strong>Error Details:</strong></p>
                <p>
                    <strong>Error Status Code:</strong>
                    {{ data.errorStatus }}
                </p>
                <p>
                    <strong>OrganizationID:</strong>
                    {{ data.orgId }}
                </p>
                <p>
                    <strong>ContractID:</strong>
                    {{ data.contractId }}
                </p>
                <p>
                    <strong>CustomerID:</strong>
                    {{ data.customerId }}
                </p>
                <p>
                    <strong>QL:</strong>
                    {{ data.quickLink }}
                </p>
                <p>
                    <strong>Date:</strong>
                    {{ data.date }}
                </p>
            </div>
        </div>
    </div>
</div>
