import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'por-admin-demo',
    templateUrl: './admin-demo.component.html',
    styleUrls: ['./admin-demo.component.scss']
})
export class AdminDemoComponent implements OnInit {
    constructor() {}

    @Input() url: string = '';
    @Input() config: string = '';

    ngOnInit(): void {}

    handleOutPutError($e: any) {
        console.log({
            $e
        });
    }
}
