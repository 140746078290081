<div class="confirm-dialog">
    <div class="dialog-content">
        <p>{{ message }}</p>
    </div>
    <ng-container [ngSwitch]="dialogType">
        <ng-container *ngSwitchCase="dialogTypeEnum.confirm">
            <div class="dialog-actions">
                <por-base-button (onclick)="sendPrompt(false)">
                    <span>{{ 'no' | translate }}</span>
                </por-base-button>
                <por-base-button (onclick)="sendPrompt(true)">
                    <span>{{ 'yes' | translate }}</span>
                </por-base-button>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="dialog-actions">
                <por-base-button (onclick)="sendPrompt(false)">
                    <span>{{ 'ok' | translate }}</span>
                </por-base-button>
            </div>
        </ng-container>
    </ng-container>
</div>
