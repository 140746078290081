<div class="contract-row-card card-wrapper">
  <div class="card">
    <div class="head-top">&nbsp;</div>
    <ul>
      <li class="row" *ngFor="let item of contractKV">
        <div class="col-2">
          <h5 class="title">{{ item.label | translate }}</h5>
        </div>
        <span>:</span>
        <div class="col-10">
          <data>{{ item.value }}</data>
        </div>
      </li>
    </ul>

    <por-base-button (onclick)="loadContractDetail()" [featureName]="'view-details'">{{
      'view details' | translate
    }}</por-base-button>

  </div>
</div>
