import { createAction, props } from '@ngrx/store';
import { Invoice } from '../../models/invoice-model';

export const loadInvoicesAction = createAction(
  '[InvoicesActions] Load InvoicesActions',
  props<{ invoices: Invoice[] }>()
);

export const setInvoicesLoading = createAction(
  '[InvoicesActions] Set InvoicesLoading',
  props<{ loading: boolean }>()
);

export const setNoMoreRecordInvoices = createAction(
  '[InvoicesActions] Set setNoMoreRecord',
  props<{ record: boolean }>()
);

export const invoicesLoading = createAction(
  '[InvoicesActions] Loading Indicator Invoices Actions',
  props<{ loading: boolean }>()
);

export const clearInvoicesinStore = createAction(
  '[InvoicesActions] Clear InvoicesinStore'
);

export const increasePageNumberInvoices = createAction(
  '[InvoicesActions] Clear increasePageNumber'
);
