import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
    selector: 'por-demo-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    constructor(
        private readonly loader: LoaderService
    ) {}
    loading$ = this.loader.loading$
    ngOnInit(): void {}
}
