import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppFacadeService } from '../../services/app-facade.service';
import { ContractCP } from '../../models/contract-model';

@Component({
    selector: 'por-multi-payment-contract-panel',
    templateUrl: './multi-payment-contract-panel.component.html',
    styleUrls: ['./multi-payment-contract-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiPaymentContractPanelComponent {
    @Input() isOpen = false; // Overlay open/close state
    @Input() trigger!: CdkOverlayOrigin; // Overlay trigger passed from parent
    @Output() readonly closeOverlay = new EventEmitter<void>(); // Emit event to parent when closing

    constructor(readonly appFacadeService: AppFacadeService) {}

    closeOverlayClick(): void {
        this.closeOverlay.emit();
    }

    removeContracts(contract: ContractCP | ContractCP[]) {
        const contractIds: string[] = Array.isArray(contract)
            ? contract.map(c => c.contractId) // If multiple contracts, get their IDs
            : [contract.contractId]; // If single contract, create an array with its ID

        this.appFacadeService.unsetSelectedContracts(contractIds);
    }
}
