import { Pipe, PipeTransform } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { Address } from '../../types';

@Pipe({
    name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {
    checkEmpty(value?: string) {
        return !isEmpty(value) ? value : '';
    }
    transform(value: Address[]): unknown {
        if (!value?.length) {
            return;
        }
        const billingAddress = value.filter((address: Partial<Address>) => address?.Type === 'Billing');
        const address = [
            this.checkEmpty(billingAddress?.[0]?.Line1),
            this.checkEmpty(billingAddress?.[0]?.Line2),
            this.checkEmpty(billingAddress?.[0]?.City),
            this.checkEmpty(billingAddress?.[0]?.GeoRegion2),
            this.checkEmpty(billingAddress?.[0]?.Country),
            billingAddress?.[0]?.PostalCode ? billingAddress?.[0]?.PostalCode : ''
        ];
        return address.filter(v => v).join(', ');
    }
}
