import { ContractState, contractsFeatureKey } from './../reducers/contracts.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const contractSelector = createFeatureSelector<ContractState>(contractsFeatureKey);

export const getPageNumber = createSelector(contractSelector, (state: ContractState) => state.pageNumber);

export const getContracts = createSelector(contractSelector, (state: ContractState) => state.contracts);

export const getLoading = createSelector(contractSelector, (state: ContractState) => state.loading);

export const getNoMoreRecord = createSelector(contractSelector, (state: ContractState) => state.noMoreRecord);
export const getSelected = createSelector(contractSelector, (state: ContractState) => state.selectedContractId);
