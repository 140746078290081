import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loading = new BehaviorSubject<boolean>(false)
  loading$ = this.loading.asObservable();
  constructor() { }

  showloader(){
    this.loading.next(true)
  }
  hideloader(){
    this.loading.next(false)
  }
}
