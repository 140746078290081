import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy, Inject, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationConfig } from '../../../../models/organization-config.model';
import { ConsumerPortalAdminConfig } from '../../../../models/consumer-portal-admin-config';
import { VersionToggleService } from '../../../../services/version-toggle';
import { OrganizationIdService } from '../../../../services/organization-id.service';
import { OrgDetail } from '../../../../models/organization-detail.model';
import { SiteSettingService } from '../../../../services/site-setting.service';
import { AppFormValidators } from '../../../validators/app.validators';
import { FileType, SubBannerFileType } from '../../../models/file-type-upload';
import { AdvanceSetupTabs } from '../../../models/advance-tabs';
import isEmpty from 'lodash-es/isEmpty';
import { PorColors } from '../../../../types/por-colors.type';
import { FeatureToggleService } from '../../../../services/feature-toggle.service';
import { AppMediatorService } from '../../../../services/app-mediator.service';
import { CONSUMER_PORTAL_APP_LANG } from '../../../../languages';
import { SelectOptions } from '../../../../shared/components/base-select/base-select.component';
import { DEFAULT_LOCALE } from '../../../../constants/default.const';

type DomainCheck = { url: string; orgDetail: OrgDetail; isAvailable: boolean };
@Component({
    selector: 'por-admin-mandatory',
    templateUrl: './admin-mandatory.component.html',
    styleUrls: ['./admin-mandatory.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminMandatoryComponent implements OnInit, OnChanges, OnDestroy {
    languageOptions: SelectOptions[];

    constructor(
        @Inject(CONSUMER_PORTAL_APP_LANG) languageOptions: SelectOptions[],
        private readonly fb: FormBuilder,
        private readonly translateService: TranslateService,
        readonly featureToggleService: FeatureToggleService,
        readonly versionService: VersionToggleService,
        private readonly organizationIdService: OrganizationIdService,
        private readonly siteSetting: SiteSettingService,
        private readonly appMediatorService: AppMediatorService
    ) {
        this.languageOptions = languageOptions;
    }
    advanceSetupTabs = AdvanceSetupTabs;
    @Input() config!: OrganizationConfig;
    @Input() adminConfig!: ConsumerPortalAdminConfig;
    @Output() readonly changeLogo: EventEmitter<string> = new EventEmitter<string>();
    @Output() readonly submitForm: EventEmitter<OrganizationConfig> = new EventEmitter<OrganizationConfig>();
    @Input() showWysiwyg = true;
    selectedPrimaryColor = '';
    selectedSecondaryColor = '';
    domain = '';
    domainName = '';
    domainCheckTimer: NodeJS.Timeout | null = null;
    subscription?: Subscription;
    subscriptions: Subscription[] = [];
    loading = false;
    checkedDomains: DomainCheck[] = [];

    mandatoryForm = this.fb.group({
        faviconUrl: ['', [AppFormValidators.imageUrlValidator]],
        logoUrl: ['', [AppFormValidators.imageUrlValidator]],
        primaryColor: ['', [Validators.required, AppFormValidators.hexValidator]],
        secondaryColor: ['', [Validators.required, AppFormValidators.hexValidator]],
        notificationEmail: [''],
        supportEmail: ['', [Validators.required, AppFormValidators.emailValidator]],
        storeName: ['', [Validators.required]],
        subDomain: ['', [Validators.required, AppFormValidators.subDomainValidator]],
        externalConsumerPortalURL: ['', [AppFormValidators.urlValidator]],
        banner1: ['', [AppFormValidators.imageUrlValidator]],
        banner1ExternalUrl: ['', [AppFormValidators.urlValidator]],
        banner2: ['', [AppFormValidators.imageUrlValidator]],
        banner2ExternalUrl: ['', [AppFormValidators.urlValidator]],
        banner3: ['', [AppFormValidators.imageUrlValidator]],
        banner3ExternalUrl: ['', [AppFormValidators.urlValidator]],
        banner4: ['', [AppFormValidators.imageUrlValidator]],
        banner4ExternalUrl: ['', [AppFormValidators.urlValidator]],
        displayServiceCertificate: [true],
        displayInspectionDocument: [true],
        secureUrlLoginRequired: [false],
        locale: [''],
        callOffAbility: [true],
        trackingTab: [true],
        requestServiceAbility: [true],
        multiplePaymentAbility: [false]
    });
    advanceViewTab$ = new BehaviorSubject<AdvanceSetupTabs>(AdvanceSetupTabs.accountSetup);

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        if (this.versionService.isAdvanceVersion()) {
            this.notificationEmail?.setValidators([Validators.required, AppFormValidators.emailValidator]);
            const subDomainSubscription: Subscription | undefined = this.mandatoryForm.get('subDomain')?.valueChanges.subscribe(val => {
                if (val && this.mandatoryForm.get('subDomain')?.valid) {
                    if (this.domainCheckTimer) clearTimeout(this.domainCheckTimer);

                    this.domainCheckTimer = setTimeout(() => {
                        this.subscription?.unsubscribe();
                        this.checkSubDomain(val ?? '');
                    }, 1000);
                }
            });
            if (subDomainSubscription) this.subscriptions.push(subDomainSubscription);
        }
        this.mandatoryForm.markAllAsTouched();
    }

    get subDomain() {
        return this.mandatoryForm.get('subDomain');
    }

    get faviconUrl() {
        return this.mandatoryForm.get('faviconUrl');
    }

    get logoUrl() {
        return this.mandatoryForm.get('logoUrl');
    }

    get primaryColor() {
        return this.mandatoryForm.get('primaryColor');
    }

    get secondaryColor() {
        return this.mandatoryForm.get('secondaryColor');
    }

    get storeName() {
        return this.mandatoryForm.get('storeName');
    }

    get notificationEmail() {
        return this.mandatoryForm.get('notificationEmail');
    }

    get supportEmail() {
        return this.mandatoryForm.get('supportEmail');
    }

    get externalConsumerPortalURL(): FormControl {
        return this.mandatoryForm.get('externalConsumerPortalURL') as FormControl;
    }

    ngOnChanges(changes: SimpleChanges): void {
        Object.entries(changes).forEach(([key, value]) => {
            if (key === 'config' && value !== undefined) {
                const orgConfig: OrganizationConfig = value.currentValue as OrganizationConfig;
                if (orgConfig?.PrimaryColor) {
                    this.selectedPrimaryColor = orgConfig?.PrimaryColor;
                } else {
                    this.selectedPrimaryColor = this.adminConfig.styling?.themeColors?.primaryColor ?? '';
                }
                this.mandatoryForm.get('primaryColor')?.setValue(this.selectedPrimaryColor);
                if (orgConfig?.SecondaryColor) {
                    this.selectedSecondaryColor = orgConfig?.SecondaryColor;
                } else {
                    this.selectedSecondaryColor = this.adminConfig.styling?.themeColors?.secondaryColor ?? '';
                }
                this.mandatoryForm.get('secondaryColor')?.setValue(this.selectedSecondaryColor);

                if (orgConfig?.LogoUrl) {
                    this.mandatoryForm.get('logoUrl')?.setValue(orgConfig?.LogoUrl);
                    this.changeLogo.emit(orgConfig?.LogoUrl);
                }

                if (orgConfig?.NotificationEmail) {
                    this.mandatoryForm.get('notificationEmail')?.setValue(orgConfig?.NotificationEmail);
                }
                if (orgConfig?.StoreName) {
                    this.mandatoryForm.get('storeName')?.setValue(orgConfig?.StoreName);
                }
                if (orgConfig?.FaviconUrl) {
                    this.mandatoryForm.get('faviconUrl')?.setValue(orgConfig?.FaviconUrl);
                }
                if (orgConfig?.SupportEmail) {
                    this.mandatoryForm.get('supportEmail')?.setValue(orgConfig?.SupportEmail);
                }
                if (orgConfig?.SubDomain) {
                    // We have to do this magic because GAPI is storing the domain name without .com
                    // So we have to remove the domain name from the subdomain
                    let domainLabelUrl = this.adminConfig.defaultSubDomain ? this.adminConfig.defaultSubDomain : this.adminConfig.uiUrl ?? '';
                    if (this.adminConfig.production) {
                        domainLabelUrl = 'https://elite.myportal.rent';
                    }
                    this.setDomains(domainLabelUrl);
                    const removedDomainName: string = orgConfig?.SubDomain.replace(this.domainName, '');
                    this.mandatoryForm.get('subDomain')?.setValue(removedDomainName);
                }
                if (orgConfig?.ExternalConsumerPortalURL) {
                    this.mandatoryForm.get('externalConsumerPortalURL')?.setValue(orgConfig?.ExternalConsumerPortalURL);
                }
                this.mandatoryForm.get('banner1')?.setValue(orgConfig?.Banner1 ? orgConfig?.Banner1 : '');
                this.mandatoryForm.get('banner1ExternalUrl')?.setValue(orgConfig?.Banner1ExternalUrl ? orgConfig?.Banner1ExternalUrl : '');
                this.mandatoryForm.get('banner2')?.setValue(orgConfig?.Banner2 ? orgConfig?.Banner2 : '');
                this.mandatoryForm.get('banner2ExternalUrl')?.setValue(orgConfig?.Banner2ExternalUrl ? orgConfig?.Banner2ExternalUrl : '');
                this.mandatoryForm.get('banner3')?.setValue(orgConfig?.Banner3 ? orgConfig?.Banner3 : '');
                this.mandatoryForm.get('banner3ExternalUrl')?.setValue(orgConfig?.Banner3ExternalUrl ? orgConfig?.Banner3ExternalUrl : '');
                this.mandatoryForm.get('banner4')?.setValue(orgConfig?.Banner4 ? orgConfig?.Banner4 : '');
                this.mandatoryForm.get('banner4ExternalUrl')?.setValue(orgConfig?.Banner4ExternalUrl ? orgConfig?.Banner4ExternalUrl : '');
                this.mandatoryForm.get('displayServiceCertificate')?.setValue(orgConfig?.DisplayServiceCertificate as boolean);
                this.mandatoryForm.get('displayInspectionDocument')?.setValue(orgConfig?.DisplayInspectionDocument as boolean);
                this.mandatoryForm.get('secureUrlLoginRequired')?.setValue(orgConfig?.SecureUrlLoginRequired as boolean);
                this.mandatoryForm.get('locale')?.setValue(orgConfig?.Locale ?? DEFAULT_LOCALE);
                this.mandatoryForm.get('callOffAbility')?.setValue(orgConfig?.CallOffAbility as boolean);
                this.mandatoryForm.get('trackingTab')?.setValue(orgConfig?.TrackingTab as boolean);
                this.mandatoryForm.get('requestServiceAbility')?.setValue(orgConfig?.RequestServiceAbility as boolean);
                this.mandatoryForm.get('multiplePaymentAbility')?.setValue(orgConfig?.MultiplePaymentAbility as boolean);
                return;
            }
        });
    }

    setColor(field: string, color: string): void {
        this.mandatoryForm.get(field)?.setValue(color);

        if (field === 'primaryColor') {
            this.selectedPrimaryColor = color;
        }
        if (field === 'secondaryColor') {
            this.selectedSecondaryColor = color;
        }
        const themeColors: PorColors = {
            /* eslint-disable @typescript-eslint/naming-convention */
            /**
             * Directly use in scss property
             */ '--por-color-primary': this.selectedPrimaryColor,
            '--por-color-secondary': this.selectedSecondaryColor,
            '--por-cp-primary-text-color': this.siteSetting.invertColor(this.selectedPrimaryColor),
            '--por-cp-secondary-text-color': this.siteSetting.invertColor(this.selectedSecondaryColor)
        };
        this.siteSetting.setColors(themeColors);
    }

    onFormChange(): void {
        this.changeLogo.emit(this.mandatoryForm.get('logoUrl')?.value ?? '');
    }

    onSubmit(): void {
        if (!this.mandatoryForm.valid) {
            return;
        }

        this.saveOrganizationConfig({
            LogoUrl: this.mandatoryForm.get('logoUrl')?.value ?? '',
            PrimaryColor: this.mandatoryForm.get('primaryColor')?.value ?? this.adminConfig.styling?.themeColors?.primaryColor,
            SecondaryColor: this.mandatoryForm.get('secondaryColor')?.value ?? this.adminConfig.styling?.themeColors?.secondaryColor,
            NotificationEmail: this.mandatoryForm.get('notificationEmail')?.value ?? '',
            StoreName: this.mandatoryForm.get('storeName')?.value ?? '',
            FaviconUrl: this.mandatoryForm.get('faviconUrl')?.value ?? '',
            SupportEmail: this.mandatoryForm.get('supportEmail')?.value ?? '',
            SubDomain: this.mandatoryForm.get('subDomain')?.value ? this.mandatoryForm.get('subDomain')?.value + this.domainName : '',
            ExternalConsumerPortalURL: this.mandatoryForm.get('externalConsumerPortalURL')?.value ?? '',
            DisplayServiceCertificate: this.mandatoryForm.get('displayServiceCertificate')?.value ?? true,
            DisplayInspectionDocument: this.mandatoryForm.get('displayInspectionDocument')?.value ?? true,
            SecureUrlLoginRequired: this.mandatoryForm.get('secureUrlLoginRequired')?.value ?? true,
            CallOffAbility: this.mandatoryForm.get('callOffAbility')?.value ?? false,
            Locale: this.mandatoryForm.get('locale')?.value ?? DEFAULT_LOCALE,
            TrackingTab: this.mandatoryForm.get('trackingTab')?.value ?? true,
            RequestServiceAbility: this.mandatoryForm.get('requestServiceAbility')?.value ?? false,
            MultiplePaymentAbility: this.mandatoryForm.get('multiplePaymentAbility')?.value ?? true
        });
    }

    saveOrganizationConfig($event: OrganizationConfig) {
        this.submitForm.emit($event);
    }

    checkSubDomain(url: string): void {
        const cachedDomain: DomainCheck | undefined = this.checkedDomains.find(x => x.url === url && x.orgDetail.OrganizationId === this.adminConfig.organizationId);
        if (cachedDomain) {
            if (!cachedDomain.isAvailable) {
                this.mandatoryForm.get('subDomain')?.setErrors({ unavailable: true });
            }
            return;
        }

        this.loading = true;
        this.subscription = this.organizationIdService
            .getOrgIdFromUrl('https://' + url + '.' + this.domain)
            .pipe(
                tap(x => {
                    let isAvailable = x === false; // if error is returned, domain is available
                    const orgDetail: OrgDetail = x as OrgDetail[][0];

                    // if not available, check if it's the same org
                    if (!isAvailable && orgDetail.OrganizationId !== this.adminConfig.organizationId) {
                        this.mandatoryForm.get('subDomain')?.setErrors({ unavailable: true });
                    } else {
                        isAvailable = true;
                    }

                    this.checkedDomains.push({ url, orgDetail, isAvailable });
                    this.loading = false;
                })
            )
            .subscribe();

        return;
    }

    setDomains(url: string): void {
        const parts: string[] = url.split('.');
        if (parts.length >= 2) {
            const withoutProtocol: string = url.replace('https://', '');
            const withoutFragment: string = withoutProtocol.replace(/\/#$/, '');
            const urlParts: string[] = withoutFragment.split('.');

            // For example https://elite.dev-consumer-portal-ui.rentamation.com/# OR 'https://elite.myportal.rent
            // domain: dev-consumer-portal-ui.rentamation.com / myportal.rent
            // endDomain: .com / .rent
            // domainName: .dev-consumer-portal-ui.rentamation / .myportal
            this.domain = urlParts.slice(1).join('.').replace('/', '');
            const endDomain: string = '.' + urlParts.slice(-1).join('.').replace('/', '');
            this.domainName = '.' + this.domain.replace(endDomain, '');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    afterUploadFile({ url, fileType, subFileType = null }: { url: string | null; fileType: FileType; subFileType: SubBannerFileType | null }): void {
        if (!isEmpty(url)) {
            switch (fileType) {
                case FileType.Logo:
                    this.mandatoryForm.controls.logoUrl.setValue(url);
                    if (url) {
                        this.changeLogo.emit(url);
                    }
                    break;
                case FileType.Icon:
                    this.mandatoryForm.controls.faviconUrl.setValue(url);
                    break;
                case FileType.Banner:
                    this.setBannersValues(url, subFileType);
                    break;
                default:
                    break;
            }
        }
    }
    /**
     * Set/Cache banner values from admin-ad-settings component
     * @param url
     * @param subFileType
     */
    setBannersValues(url: string | null, subFileType: SubBannerFileType | null) {
        switch (subFileType) {
            case SubBannerFileType.Banner1:
                this.mandatoryForm.controls.banner1.setValue(url);
                break;
            case SubBannerFileType.Banner2:
                this.mandatoryForm.controls.banner2.setValue(url);
                break;
            case SubBannerFileType.Banner3:
                this.mandatoryForm.controls.banner3.setValue(url);
                break;
            case SubBannerFileType.Banner4:
                this.mandatoryForm.controls.banner4.setValue(url);
                break;
            default:
                break;
        }
    }

    onModelChange(value: boolean, formControlName: string): void {
        this.mandatoryForm.get(`${formControlName}`)?.setValue(value);
    }

    handleSelectedLocale(value: string): void {
        this.mandatoryForm.controls.locale.setValue(value);
    }
}
