import { createAction, props } from '@ngrx/store';
import { ContractCP } from '../../models/contract-model';

export const loadContractAction = createAction('[ContractActions] Load ContractActions', props<{ contracts: ContractCP[] }>());

export const setLoading = createAction('[ContractActions] Set ContractLoading', props<{ loading: boolean }>());

export const setNoMoreRecord = createAction('[ContractActions] Set setNoMoreRecord', props<{ record: boolean }>());

export const contractsLoading = createAction('[ContractActions] Load ContractActions', props<{ loading: boolean }>());

export const clearContractinStore = createAction('[ContractActions] Clear ContractinStore');

export const increasePageNumberContract = createAction('[ContractActions] Clear increasePageNumber');

export const openContract = createAction('[ContractActions] Open Contract', props<{ selectedContractId: string }>());
