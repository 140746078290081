import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, catchError, debounceTime, map, switchMap, take, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateUtility } from '../../../date.utility';
import { DateFormatType } from '../../../enums/date-format-type.enum';
import { ContractDetail, Payment } from '../../../models/contract-model';
import { LoggerService, NotificationService } from '../../../services';
import { CreateTransactionResponse } from '../../models/create-transaction-response-model';
import { PaymentAppPayInput } from '../../models/paymentInput';
import { PaymentAppPayOutput, PayOutputEvent } from '../../models/paymentOutput';
import { PaymentProps } from '../../models/payment-props.enum';
import { paymentFormValidator } from '../../../validators/payment-form.validator';
import { select, Store } from '@ngrx/store';
import { getCustomer } from '../../../store/selector/customer.selectors';
import { Customer } from '../../../models/consumer';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CPEnvironment } from '../../../models';
import { ConsumerPortalEnviromentConfig } from '../../../models/environment-consumer-portal';
import { PaymentMinMaxBoundary, PaymentResponse } from '../../models/payment-data.model';
import { VersionToggleService } from '../../../services/version-toggle';
import { PorTransactionService } from '../../../por-transaction.service';
import { AppFacadeService } from '../../../services/app-facade.service';
import { AppMediatorService } from '../../../services/app-mediator.service';

@Component({
    selector: 'por-payment-page',
    templateUrl: './por-payment-page.component.html',
    styleUrls: ['./por-payment-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PorPaymentPageComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        private readonly appFacadeService: AppFacadeService,
        private readonly translateService: TranslateService,
        private readonly logger: LoggerService,
        private readonly notification: NotificationService,
        private readonly appMediatorService: AppMediatorService,
        private readonly store: Store,
        private readonly featureToggleService: FeatureToggleService,
        public versionToggle: VersionToggleService,
        private readonly porTransactionService: PorTransactionService,
        @Inject(LOCALE_ID) locale: string,
        @Inject(CPEnvironment) private readonly appConfig: ConsumerPortalEnviromentConfig
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    customer!: Customer;
    @Input() enableBackButton = false;
    @Input() customerEmail!: string | undefined;
    @Input() customerId = '';
    @Input() contract!: ContractDetail;
    @Input() organizationId!: string;
    transactionId!: string;
    isTransactionGoing = false;
    @Output() readonly appOutput = new EventEmitter();
    @Output() readonly paymentSuccess: EventEmitter<Payment> = new EventEmitter<Payment>();
    dateFormat!: string;
    featureService = this.featureToggleService;
    porPayURL = this.appConfig.porPayScriptURL;

    private readonly subscriptions: Subscription[] = [];
    apiUrl = this.appConfig.gapiApiBaseUrl;
    paymentAppInput$: BehaviorSubject<string> = new BehaviorSubject('');
    apiKey = '';
    @Output() readonly closePanel = new EventEmitter<boolean>();

    paymentBoundaryMinMax!: PaymentMinMaxBoundary[];
    minimumPaymentDue = 0;
    isDialogEnabled = false;
    dialogMessage = '';
    isNoPaymentBoundaryFound = new BehaviorSubject<boolean>(false);

    form: FormGroup = new FormGroup(
        {
            paymentType: new FormControl(PaymentProps.TYPE_FULL_AMOUNT_DUE, {
                validators: [Validators.required]
            }),
            amount: new FormControl('')
        },
        {
            validators: [paymentFormValidator()]
        }
    );
    private readonly isPaymentBoundaryAvailable = new BehaviorSubject<boolean>(false);
    isPaymentBoundaryAvailable$ = this.isPaymentBoundaryAvailable.pipe(
        tap(() => {
            this.appFacadeService.setLoading(true);
        }),
        switchMap(() => {
            return this.appMediatorService.porPayService.getMinMaxFromPaymentBoundary(this.customerId, this.contract.Id).pipe(
                map((res: PaymentMinMaxBoundary[]) => {
                    this.appFacadeService.setLoading(false);
                    if (res.length === 0) {
                        this.isNoPaymentBoundaryFound.next(true);
                        return false;
                    }
                    this.paymentBoundaryMinMax = res;
                    return true;
                }),
                catchError(e => {
                    this.appFacadeService.setLoading(false);
                    throw new Error(this.translateService.instant('somethingWentWrong', e));
                })
            );
        })
    );

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        this.transactionId = '';
        this.subscriptions.push(
            this.store.pipe(select(getCustomer)).subscribe((customer: Customer) => {
                this.customer = customer;
            })
        );
    }

    ngAfterViewInit(): void {
        /**
         * IF min is null/0 then the min Amount is 1.
         */
        this.form.controls['amount'].setValidators([Validators.min(this.minimumPaymentDue ?? 1), Validators.max(this.contract.AmountDue)]);
        this.form.controls['amount'].updateValueAndValidity();
        this.subscriptions.push(
            this.form.valueChanges.pipe(debounceTime(100)).subscribe(val => {
                if (val?.paymentType === PaymentProps.TYPE_FULL_AMOUNT_DUE) {
                    this.form.controls?.['amount'].setValue('');
                }
            })
        );
    }

    catchOutput(output: PayOutputEvent): void {
        this.logger.logInfo(output?.detail);
        const detail: PaymentAppPayOutput = output?.detail as PaymentAppPayOutput;
        if (detail?.ResponseCode === 200) {
            this.notification.success(detail?.Message);
            this.notification.success(this.translateService.instant('Please wait till we verify the received payment') + '.');
            // verifying Transaction

            const phone: string = this.customer?.Phones?.[0]?.Number ? this.customer?.Phones?.[0]?.Number : '0000000000';
            const email: string = this.customer?.Emails?.[0]?.Email ? this.customer?.Emails?.[0]?.Email : this.customerEmail ? this.customerEmail : 'default@por.com';

            this.appFacadeService.setLoading(true);
            let address = '';
            if (this.customer?.Addresses && this.customer.Addresses.length > 0) {
                address = this.customer.Addresses?.[0]?.Line1 ? this.customer.Addresses?.[0]?.Line1 : '';
            }

            this.subscriptions.push(
                this.appMediatorService.porPayService
                    .verifyPayment(this.customerId, {
                        organizationId: this.organizationId,
                        amount: detail?.TransactionData?.Amount_Processed ? this.convertCurrency(detail?.TransactionData?.Amount_Processed) : 0,
                        contractId: this.contract?.Id,
                        referenceNumber: detail?.TransactionId ? detail?.TransactionId : '',
                        currency: detail?.TransactionData?.CurrencyCode ? detail?.TransactionData?.CurrencyCode : 'USD', // default to USD
                        depotId: this.contract?.DepotId ? this.contract?.DepotId : '0',
                        address,
                        companyName: this.customer?.CompanyName ? this.customer?.CompanyName : 'POR',
                        email,
                        firstName: this.customer?.FirstName ? this.customer?.FirstName : this.customer?.Name,
                        lastName: this.customer?.LastName ? this.customer?.LastName : this.customer?.Name,
                        phone
                    })
                    .pipe(
                        take(1),
                        tap({
                            error: (err: HttpErrorResponse) => {
                                this.logger.logError(err);
                                this.logger.alertDevError(err);
                                this.appOutput.emit(detail);
                                this.appFacadeService.setLoading(false);
                                this.openDialog(this.translateService.instant('VerifyTransactionFailed') + err?.error?.message);
                            },
                            next: (res: Payment) => {
                                this.logger.logWarning(res);
                                this.paymentSuccess.emit(res);
                                this.notification.success(this.translateService.instant('Thanks for your payment') + '.');
                            },
                            complete: () => {
                                this.appOutput.emit(detail);
                                this.appFacadeService.setLoading(false);
                                this.closePanel.emit(true);
                                this.porTransactionService.setTransactionSuccess(true);
                            }
                        })
                    )
                    .subscribe()
            );
        } else if (detail?.ResponseCode === 400 && detail?.Error === 'Validation failed') {
            this.logger.logError(output?.detail, '', true);
            this.openDialog(this.translateService.instant('PaymentWebComponentFailed') + output?.detail?.Message);
        } else {
            this.appFacadeService.setLoading(false);
            this.logger.logError(output?.detail, '', true);
            /**
             * Reset transaction id and paymentAppInput,
             * so that it show payment form and remove payment web component
             */
            this.transactionId = '';
            this.paymentAppInput$.next('');
            this.openDialog(this.translateService.instant('PaymentWebComponentFailed') + (output?.detail?.Message ? output?.detail?.Message : ''));
        }
    }

    formatCurrencyForPorPay(input: string): string {
        const decimalIndex: number = input.indexOf('.');

        if (decimalIndex === -1) {
            return input + '00';
        }

        const wholeNumberPart: string = input.slice(0, decimalIndex);
        const fractionalPart: string = input.slice(decimalIndex + 1);
        const paddedFractionalPart: string = fractionalPart.padEnd(2, '0');

        return wholeNumberPart + paddedFractionalPart.slice(0, 2);
    }

    /**
     * Convert smallest denomination of number to actual value, eg. 100(cents) to $1
     * @param {number} amount
     * @returns {number}
     */
    private convertCurrency(amount: number): number {
        const dollars = amount / 100;

        // Format the result to two decimal places
        return parseFloat(dollars.toFixed(2));
    }

    createTransaction(): void {
        if (this.isTransactionGoing) {
            return;
        }
        if (this.form.valid && this.customerId) {
            this.appFacadeService.setLoading(true);

            let amountBeingPaidBeforeFee: number = this.contract.AmountDue;
            if (this.form.get('paymentType')?.value === PaymentProps.TYPE_OTHER_AMOUNT) {
                amountBeingPaidBeforeFee = Number(this.form.get('amount')?.value);
            } else if (this.form.get('paymentType')?.value === PaymentProps.TYPE_MIN_AMOUNT_DUE) {
                amountBeingPaidBeforeFee = this.minimumPaymentDue;
            }
            const amountToPay: string = this.formatCurrencyForPorPay(String(amountBeingPaidBeforeFee));

            this.isTransactionGoing = true;
            this.subscriptions.push(
                this.appMediatorService.porPayService
                    .createPayment(this.customerId, 'USD', Number(amountToPay), this.organizationId, this.contract.DepotId, this.contract.Id, Number(amountBeingPaidBeforeFee))
                    .pipe(
                        take(1),
                        tap({
                            error: (error: HttpErrorResponse) => {
                                this.appFacadeService.setLoading(false);
                                this.isTransactionGoing = false;
                                this.openDialog(this.translateService.instant('CreateTransactionFailed') + error?.error.message);
                            },
                            next: (res: unknown) => {
                                this.appFacadeService.setLoading(false);
                                this.isTransactionGoing = false;
                                const response: CreateTransactionResponse = res as CreateTransactionResponse;
                                if (response.token && response.token === 'NO_PAYMENT_METHOD_AVAILABLE') {
                                    this.openDialog(this.translateService.instant('CreateTransactionFailed') + this.translateService.instant('NoPaymentMethodAvailable'));
                                    return;
                                }
                                this.transactionId = response?.transactionId;
                                this.apiKey = response?.apiKey;
                                this.appendElement();
                            },
                            complete: () => {
                                this.appFacadeService.setLoading(false);
                            }
                        })
                    )
                    .subscribe()
            );
        }
    }

    appendElement(): void {
        let email = '',
            country = '',
            address1 = '',
            address2 = '',
            city = '',
            state = '',
            postalCode = '';
        if (this.customer?.Emails && this.customer.Emails.length > 0) {
            email = this.customer.Emails?.[0]?.Email;
        }
        if (this.customer?.Addresses && this.customer.Addresses.length > 0) {
            country = this.customer.Addresses?.[0]?.Country ? this.customer.Addresses?.[0]?.Country : 'US';
            city = this.customer.Addresses?.[0]?.City ? this.customer.Addresses?.[0]?.City : '';
            state = this.customer.Addresses?.[0]?.Province ? this.customer.Addresses?.[0]?.Province : '';
            postalCode = this.customer.Addresses?.[0]?.PostalCode ? this.customer.Addresses?.[0]?.PostalCode : '';
            address1 = this.customer.Addresses?.[0]?.Line1 ? this.customer.Addresses?.[0]?.Line1 : '';
            address2 = this.customer.Addresses?.[0]?.Line2 ? this.customer.Addresses?.[0]?.Line2 : '';
        }

        const configuration: PaymentAppPayInput = {
            /* eslint-disable @typescript-eslint/naming-convention */
            /**
             * Note camelCase : DB Model
             */
            ApiKey: this.apiKey,
            TransactionId: this.transactionId,
            ApiUrl: this.apiUrl,
            CustomerData: {
                Name: this.customer?.Name ? this.customer?.Name : '',
                Country: country,
                Email: email,
                Address1: address1,
                Address2: address2,
                City: city,
                State: state,
                PostalCode: postalCode
            }
        };
        this.paymentAppInput$.next(JSON.stringify(configuration));
    }

    selectOtherType(): void {
        this.form.get('paymentType')?.setValue(PaymentProps.TYPE_OTHER_AMOUNT);
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    /**
     * Show Payment Radio Buttons
     * @param {PaymentProps} button
     * @returns
     */
    showPaymentRadioButtons(button: PaymentProps): boolean {
        const { min, max } = this.filterPaymentBoundary();
        switch (button) {
            case PaymentProps.TYPE_FULL_AMOUNT_DUE:
                return this.featureService.isAvailable('payFullAmount') ? true : false;
            case PaymentProps.TYPE_OTHER_AMOUNT:
                // if both min and max are same, We want to hide Pay Other Amount button
                if (min > 0 && max > 0 && min === max) {
                    return false;
                }
                return (min < this.contract.AmountDue && max > this.contract.AmountDue) || max <= this.contract.AmountDue ? true : false;
            case PaymentProps.TYPE_MIN_AMOUNT_DUE:
                if (min === 0 || min === 1 || min === max) {
                    return false;
                }
                return (min < this.contract.AmountDue && max > this.contract.AmountDue) || max <= this.contract.AmountDue ? true : false;
            default:
                return false;
        }
    }

    filterPaymentBoundary(): { min: number; max: number } {
        /**
         * Initilize min and max with default non zero value
         */
        let min = this.paymentBoundaryMinMax[0].minAmount;
        let max = this.paymentBoundaryMinMax[0].maxAmount;
        this.paymentBoundaryMinMax.map((boundary: PaymentMinMaxBoundary) => {
            if (boundary.minAmount < min) {
                min = boundary.minAmount;
            }
            if (boundary.maxAmount > max) {
                max = boundary.maxAmount;
            }
        });
        /**
         * IF min is 0 then the min Amount is 1.
         */
        this.minimumPaymentDue = min === 0 ? 1 : min;
        return { min: min, max: max };
    }

    closeDialog(): void {
        this.isDialogEnabled = false;
        return;
    }

    private openDialog(message: string): void {
        this.dialogMessage = message;
        this.isDialogEnabled = true;
        return;
    }
}
