import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProductDetail } from '../../models/product-detail';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { AppFacadeService } from '../../services/app-facade.service';
import { DocumentData } from '../../store/reducers/documents.reducer';
import { ModalService } from '../../services';
import { SendLinkRequest } from '../../models/send-link-request';
import { GetDocumentParam } from '../../models/contract-model';
import { AppMediatorService } from '../../services/app-mediator.service';

@Component({
    selector: 'por-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDetailComponent implements OnInit, OnDestroy {
    @Input() productDetail?: ProductDetail;
    @Input() customerId = '';
    @Input() contractId = '';
    @Output() readonly closePanel = new EventEmitter<boolean>(false);
    @Input() uiUrl: string | undefined;
    isMobileLayout = false;
    subscriptions: Subscription[] = [];
    sendLinkRequestData: SendLinkRequest = {} as SendLinkRequest;
    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        readonly featureToggleService: FeatureToggleService,
        readonly appFacadeService: AppFacadeService,
        private readonly appMediatorService: AppMediatorService,
        private readonly modal: ModalService
    ) {}

    ngOnInit(): void {
        /**
         * Ref: https://wiki.pointofrental.com/e/en/Departments/Engineering/Consumer-Portal/Quick-Link
         */
        this.sendLinkRequestData = {
            id: this.productDetail?.StockId ?? this.productDetail?.ProductId ?? '',
            contractId: this.contractId,
            itemName: this.productDetail?.Name ?? '',
            serialNumber: this.productDetail?.SerialNumber ?? '',
            customerId: this.customerId,
            lineItemId: this.productDetail?.Id ?? ''
        };
        this.subscriptions.push(
            this.breakpointObserver.observe(['(max-width: 992px)']).subscribe((result: BreakpointState) => {
                this.isMobileLayout = result.matches;
            })
        );
        const organizationType: string = this.appMediatorService.localStorageService?.getCurrentUser?.organizationType as string;
        if (this.contractId) {
            const contractdetails: GetDocumentParam = {
                customerId: this.customerId,
                contractId: this.contractId,
                orgType: organizationType,
                documentValue: this.productDetail?.Id
            };
            this.appFacadeService.loadDocuments(contractdetails);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
        this.appFacadeService.cancelLoadDocuments();
        this.appFacadeService.emptyDocument();
    }

    isContentAvailable(propertyName: string): boolean {
        switch (propertyName) {
            case 'Images':
                return this.productDetail && this.productDetail?.Images && this.productDetail?.Images?.length > 0 ? true : false;
            case 'StoredFiles':
                return this.productDetail && this.productDetail?.StoredFiles && this.productDetail?.StoredFiles?.length > 0 ? true : false;
            case 'Name':
                return this.productDetail && this.productDetail?.Name ? true : false;
            case 'SerialNumber':
                return this.productDetail && this.productDetail?.SerialNumber ? true : false;
            case 'Description':
                return this.productDetail && this.productDetail?.Description ? true : false;
            case 'OtherUrls':
                return this.productDetail && this.productDetail?.OtherUrls && this.productDetail?.OtherUrls.length > 0 ? true : false;
            default:
                return false;
        }
    }

    openDocumentUrl(documentType: string): void {
        this.appFacadeService.openPdfdocument(documentType).subscribe((response: DocumentData[]) => {
            if (response.length > 0) {
                this.appFacadeService.pdf.openPdfinNewTab(response[0].url);
            }
        });
    }

    handleRentalFormReturn(): void {
        this.closeModal();
    }

    // below event can use as use as output decorator
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleSendDocumentFormSubmission($event: SendLinkRequest): void {
        this.closeModal();
    }

    openModal(): void {
        this.modal.open('product-detail-modal');
    }

    closeModal(): void {
        this.modal.close('product-detail-modal');
    }
}
