<div class="multipayment-panel" *ngIf="appFacadeService.getContractsTotal() | async as total">
    <por-overlay [layout]="'right-panel'" [featureName]="'multiple-payment-panel'" [isOpen]="isOpen" [trigger]="trigger" (closeOverlayClick)="closeOverlayClick()">
        <div class="multipayment-panel-wrapper mb-2 mt-2">
            <ng-container
                *ngIf="appFacadeService.getSelectedNonCreditContracts() | async as contracts"
                [ngTemplateOutlet]="selectedContract"
                [ngTemplateOutletContext]="{ $implicit: contracts }"></ng-container>
            <ng-container
                *ngIf="appFacadeService.getSelectedCreditContracts() | async as contracts"
                [ngTemplateOutlet]="selectedCredits"
                [ngTemplateOutletContext]="{ $implicit: contracts }"></ng-container>
            <ng-container [ngTemplateOutlet]="totalContracts"></ng-container>

            <div class="warnings text-align-center" *ngIf="total < 1">
                <p>{{ 'PayMinimumAmountDueToProcessPayment1' | translate }} {{ '1' | formatCurrency }} {{ 'PayMinimumAmountDueToProcessPayment2' | translate }}</p>
            </div>

            <div class="justify-content-center mt-2">
                <por-base-button [class]="'btn-primary'" [theme]="'primary'" [featureName]="'multipayment-panel-continue'" [disabled]="total < 1">
                    {{ 'Continue' | translate }}
                </por-base-button>
            </div>
        </div>
    </por-overlay>
</div>
<ng-template #selectedContract let-contracts>
    <div class="selected-contracts" *ngIf="contracts.length > 0">
        <h3>{{ 'SelectedContracts' | translate }}</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>
                        <por-base-button
                            title="{{ 'Clear All' | translate }}"
                            [featureName]="'multipayment-panel-delete-all-contracts'"
                            icon="close"
                            [iconClass]="'font-18'"
                            [class]="'transparent'"
                            (onclick)="removeContracts(contracts)"></por-base-button>
                    </th>
                    <th>{{ 'Contract #' | translate }}</th>
                    <th>{{ 'Amount Due' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let contract of contracts">
                    <td>
                        <por-base-button
                            (onclick)="removeContracts(contract)"
                            [featureName]="'multipayment-panel-delete-contract'"
                            icon="close"
                            [iconClass]="'font-18'"
                            [class]="'transparent'"></por-base-button>
                    </td>
                    <td>{{ contract.contractId }}</td>
                    <td class="text-align-right">{{ contract.AmountDue | formatCurrency }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="2">{{ 'TotalContracts' | translate }}</th>
                    <th class="text-align-right">{{ appFacadeService.getSelectedContractsTotal() | async | formatCurrency }}</th>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>
<ng-template #selectedCredits let-contracts>
    <div class="selected-credits mt-1" *ngIf="contracts.length > 0">
        <h3>{{ 'SelectedCredits' | translate }}</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>
                        <por-base-button
                            title="{{ 'Clear All' | translate }}"
                            [featureName]="'multipayment-panel-delete-all-contracts'"
                            icon="close"
                            [iconClass]="'font-18'"
                            [class]="'transparent'"
                            (onclick)="removeContracts(contracts)"></por-base-button>
                    </th>
                    <th>{{ 'Contract #' | translate }}</th>
                    <th>{{ 'Credit' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let contract of contracts">
                    <td>
                        <por-base-button
                            (onclick)="removeContracts(contract)"
                            [featureName]="'multipayment-panel-delete-contract'"
                            icon="close"
                            [iconClass]="'font-18'"
                            [class]="'transparent'"></por-base-button>
                    </td>
                    <td>{{ contract.contractId }}</td>
                    <td class="text-align-right">{{ contract.AmountDue | formatCurrency }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="2">{{ 'TotalCredits' | translate }}</th>
                    <th class="text-align-right">{{ appFacadeService.getSelectedCreditContractsTotal() | async | formatCurrency }}</th>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>
<ng-template #totalContracts>
    <div class="total-contracts-due mt-1">
        <table class="table">
            <tbody>
                <tr>
                    <th colspan="2" class="bg-black">{{ 'TotalDue' | translate }}</th>
                    <th class="text-align-right bg-black">{{ appFacadeService.getContractsTotal() | async | formatCurrency }}</th>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>
