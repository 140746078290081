<div id="apx" class="customer-portal-wrappper-cp">
    <ng-container *ngIf="(appFacadeService.getAdminLoaded() | async) && (appFacadeService.getAuthenticated() | async); else showError">
        <ng-container *ngIf="isSyrnix(); else adminTabs">
            <ng-container class="holder" *ngIf="configService.adminInputs$ | async as adminInputs">
                <por-admin-header [config]="(orgConfig$ | async) || {}" [adminConfig]="adminInputs" [showTabs]="false"></por-admin-header>
                <por-admin-mandatory
                    [showWysiwyg]="false"
                    [config]="(orgConfig$ | async) || {}"
                    [adminConfig]="adminInputs"
                    (changeLogo)="onChangeLogo($event)"
                    (submitForm)="onSubmitMandatoryForm($event)"></por-admin-mandatory>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #showError>
        <div class="loading">
            <strong>{{ (errorMessage$ | async)?.message || ('Please wait' | translate) }}</strong>
        </div>
    </ng-template>

    <ng-template #adminTabs>
        <ng-container class="holder" *ngIf="configService.adminInputs$ | async as adminInputs">
            <ng-container *ngIf="versionLoaded$ | async">
                <por-admin-header [config]="(orgConfig$ | async) || {}" [adminConfig]="adminInputs" [showTabs]="true"></por-admin-header>
                <ng-container class="customer-portal-admin" [ngSwitch]="appFacadeService.getActiveTab() | async">
                    <ng-container *ngSwitchDefault>
                        <por-admin-overview [adminConfig]="adminInputs"></por-admin-overview>
                    </ng-container>
                    <ng-container *ngSwitchCase="'AdminWebpay'">
                        <por-admin-webpay [config]="(orgConfig$ | async) || {}"></por-admin-webpay>
                    </ng-container>
                    <ng-container *ngSwitchCase="'AdminSetup'">
                        <por-admin-mandatory
                            [showWysiwyg]="true"
                            [config]="(orgConfig$ | async) || {}"
                            [adminConfig]="adminInputs"
                            (changeLogo)="onChangeLogo($event)"
                            (submitForm)="onSubmitMandatoryForm($event)"></por-admin-mandatory>
                    </ng-container>
                    <ng-container *ngSwitchCase="'AdminAccountList'">
                        <por-admin-account-list></por-admin-account-list>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-template>
</div>
