import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/state/app.state';
import * as ConfigActions from '../store/actions/config.actions';
import * as ConfigSelectors from '../store/selector/config.selectors';
import { ConsumerPortalConfig } from '../models';
import { combineLatest, map, Observable, take } from 'rxjs';
import { setLoading } from '../store/actions/loder.actions';
import { selectIsLoading } from '../store/selector/loader.selector';
import { PendoService } from '../pendo/services/pendo.service';
import { DocumentData } from '../store/reducers/documents.reducer';
import { selectDocumentDataByType } from '../store/selector/document.selectors';
import { PdfService } from './pdf.service';
import { emptyDocumentData, loadDocument, cancelLoadDocuments } from '../store/actions/document.actions';
import { VersionToggleService } from './version-toggle';
import { GetDocumentParam } from '../models/contract-model';
import { EventLogsRequest } from '../models/event-logs.interface';
import { saveEventLogsData } from '../store/actions/event-logs.action';
import { FeatureToggleService } from './feature-toggle.service';
import { ComponentsToLoad } from '../enums/components-to-load.enum';
import { setActiveTab, setPaymentSectionEnabled } from '../store/actions/active-tab.actions';
import { selectActiveTab, selectPaymentSectionEnabled } from '../store/selector/active-tab.selectors';
import { selectContractCurrency, selectCustomerCurrency } from '../store/selector/currency-code.selectors';
import { setAuthenticated } from '../store/actions/auth.actions';
import { selectIsAuthenticated } from '../store/selector/auth.selector';
import { selectLoaded } from '../store/selector/loaded.selectors';
import { setAdminLoading } from '../store/actions/loaded.actions';

/**
 * This service is used to manage Consumer Portal Config to store
 */
@Injectable({
    providedIn: 'root'
})
export class AppFacadeService {
    constructor(
        private readonly store: Store<AppState>,
        public readonly pendo: PendoService,
        public readonly pdf: PdfService,
        public readonly versionToggleService: VersionToggleService,
        public readonly featureToggleService: FeatureToggleService
    ) {}
    setConfig(config: ConsumerPortalConfig): void {
        this.store.dispatch(ConfigActions.setConfig({ config }));
    }

    getConfig(): Observable<ConsumerPortalConfig> {
        return this.store.pipe(select(ConfigSelectors.selectConfig));
    }

    setLoading(isLoading: boolean): void {
        this.store.dispatch(setLoading(isLoading));
    }

    getLoader(): Observable<boolean> {
        return this.store.pipe(select(selectIsLoading));
    }

    getPdfdocument(docType: string): Observable<DocumentData[]> {
        return this.store.pipe(select(selectDocumentDataByType(docType)));
    }

    emptyDocument() {
        this.store.dispatch(emptyDocumentData());
    }

    loadDocuments(contractdetails: GetDocumentParam) {
        this.store.dispatch(loadDocument({ contractdetails }));
    }

    openPdfdocument(docType: string): Observable<DocumentData[]> {
        return this.store.pipe(take(1), select(selectDocumentDataByType(docType)));
    }

    cancelLoadDocuments() {
        this.store.dispatch(cancelLoadDocuments());
    }

    /**
     * Dispatch action to save event logs
     * @param data
     */
    saveEventLogs(data: EventLogsRequest) {
        this.store.dispatch(saveEventLogsData({ payload: data }));
    }

    /**
     * Set active tab to state
     * @param activeTab
     */
    setActiveTab(activeTab: ComponentsToLoad): void {
        if (activeTab === ComponentsToLoad.Logout) {
            this.updateLocation(activeTab);
            return;
        }
        this.store.dispatch(setActiveTab({ activeTab }));
    }

    /**
     * Get the active tab
     * @returns Observable<ComponentsToLoad>
     */
    getActiveTab(): Observable<ComponentsToLoad> {
        return this.store.pipe(select(selectActiveTab));
    }

    /**
     * Update location history on address bar
     * @param tab
     * @returns
     */
    updateLocation(tab: ComponentsToLoad | string): void {
        const currentState = history.state || window.location.href;
        if (this.versionToggleService.isConsumerQuickLinkVersion()) {
            return;
        }
        if (tab === ComponentsToLoad.Logout) {
            /**
             * Set to default view when logout performed
             */
            this.setActiveTab(ComponentsToLoad.AccountSummary);
            history.replaceState(currentState, '', `#`);
            return;
        }
        history.replaceState(currentState, '', `#${tab}`);
    }

    getCurrency(): Observable<string | null> {
        return combineLatest([this.store.pipe(select(selectContractCurrency)), this.store.pipe(select(selectCustomerCurrency))]).pipe(
            map(([contractCurrency, customerCurrency]) => contractCurrency || customerCurrency)
        );
    }

    setAuthenticated(isAuthenticated: boolean): void {
        this.store.dispatch(setAuthenticated(isAuthenticated));
    }

    getAuthenticated(): Observable<boolean> {
        return this.store.pipe(select(selectIsAuthenticated));
    }

    /**
     * Set payment section enabled to state
     * @param setPaymentSectionEnabled
     */
    setPaymentSectionEnabled(action: boolean): void {
        return this.store.dispatch(setPaymentSectionEnabled({ enabled: action }));
    }

    /**
     * Get payment section enabled or not
     * @returns Observable<boolean>
     */
    isPaymentSectionEnabled(): Observable<boolean> {
        return this.store.pipe(select(selectPaymentSectionEnabled));
    }

    setAdminLoaded(isLoaded: boolean): void {
        this.store.dispatch(setAdminLoading(isLoaded));
    }

    getAdminLoaded(): Observable<boolean> {
        return this.store.pipe(select(selectLoaded));
    }
}
