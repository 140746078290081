import isEmpty from 'lodash-es/isEmpty';
import { Component, EventEmitter, Input, OnInit, Output, HostListener, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { AppFacadeService } from '../../services/app-facade.service';
import { NotificationService } from '../../services/notification.service';
import { BehaviorSubject, debounceTime, delay, distinctUntilChanged, map, Observable, Subscription, tap } from 'rxjs';
import {
    ApplyColumnStateParams,
    CellClickedEvent,
    ColDef,
    ColumnApi,
    ColumnState,
    CsvExportParams,
    GridApi,
    GridReadyEvent,
    IsFullWidthRowParams,
    ProcessCellForExportParams
} from 'ag-grid-community';
import { ConfigurationService } from '../../services/configuration.service';
import { InvoicesService } from '../../services/invoices.service';
import { clearInvoicesinStore, increasePageNumberInvoices } from '../../store/actions/invoices.actions';
import { getInvoices, getNoMoreRecordInvoices, getLoading } from '../../store/selector/invoices.selectors';
import { TranslateService } from '@ngx-translate/core';
import { formatDateType } from '../../types';
import { InvoicesFullWidthRowComponent } from '../invoices-full-width-row/invoices-full-width-row.component';
import { Invoice, InvoiceTotalAmountDue } from '../../models/invoice-model';
import { LoggerService } from '../../services/logger.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FilterOutput } from '../../shared/models/filters.model';
import { ContractCP, ContractDetail } from '../../models/contract-model';
import { ContractService } from '../../services/contract.service';
import { ModalService } from '../../services';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { AmountRendererComponent } from './renderer/amount/amount-renderer.component';
import { AppMediatorService } from '../../services/app-mediator.service';

@Component({
    selector: 'por-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesComponent implements OnInit, OnDestroy {
    public context;
    invoiceTotalAmountDue$!: Observable<number | undefined>;
    invoices$: Observable<Invoice[]> = this.store.select(getInvoices);
    noMoreRecord$: Observable<boolean> = this.store.select(getNoMoreRecordInvoices);
    customerId!: string;
    private readonly subscriptions: Subscription[] = [];
    gridApi!: GridApi;
    gridColumnApi!: ColumnApi;
    headerHeight = 35;
    rowHeight = 65;

    inputs: ConsumerPortalConfig | undefined;
    gridReady$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    gridReadyObservable$: Observable<boolean> = this.gridReady$.pipe(delay(1000));

    @Input() config: string | undefined;
    @Input() width: string | undefined;
    @Input() height: string | undefined;
    @Output() readonly elementLoaded = new EventEmitter<boolean>();
    @Output() readonly appOutput: EventEmitter<ContractCP[] | Invoice[]> = new EventEmitter<ContractCP[] | Invoice[]>();

    isDetailPageOpened = false;
    uiUrl: string | undefined;
    isInvoiceDetailPageOpened = false;
    contractDetail!: ContractDetail[];
    @Input() initialStatusFilters?: string[];
    loading = false;
    filters: FilterOutput | undefined;
    invoiceDetail!: Invoice;
    applyWidthStyle!: { [klass: string]: string };

    constructor(
        private readonly appFacadeService: AppFacadeService,
        private readonly store: Store,
        private readonly invoiceService: InvoicesService,
        private readonly notification: NotificationService,
        private readonly configService: ConfigurationService,
        private readonly datePipe: FormatDatePipe,
        private readonly translateService: TranslateService,
        private readonly appMediatorService: AppMediatorService,
        private readonly logger: LoggerService,
        private readonly contractService: ContractService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly modal: ModalService
    ) {
        store.dispatch(clearInvoicesinStore());
        this.context = {
            componentParent: this
        };
    }

    public filterComponentProps: { searchPlaceHolder: string; dateLabel: string } = {
        searchPlaceHolder: this.translateService.instant('QuickSearch'),
        dateLabel: this.translateService.instant('SelectInvoiceDateRange')
    };

    public columnDefs: ColDef[] = [];
    public defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        wrapText: false,
        sortingOrder: ['asc', 'desc']
    };
    public fullWidthCellRenderer = InvoicesFullWidthRowComponent;

    public getScreenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

    isFullWidth = false;

    public isFullWidthRow: (params: IsFullWidthRowParams) => boolean = () => {
        return this.isFullWidth;
    };
    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth.next(window.innerWidth);
    }

    private readonly formatDate = (params: formatDateType) => {
        const date = this.datePipe.transform(params.value);
        return `<span class="theme-table-row">${date}</span>`;
    };

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        const columnState: ApplyColumnStateParams = {
            state: [
                {
                    colId: 'InvoiceDate',
                    sort: 'desc'
                }
            ]
        };
        this.gridColumnApi.applyColumnState(columnState);
        this.gridApi.sizeColumnsToFit();
        const invoiceNumberCol: ColumnState | undefined = this.gridColumnApi.getColumnState().find(state => state.colId === 'InvoiceNumber');
        const amountDueCol: ColumnState | undefined = this.gridColumnApi.getColumnState().find(state => state.colId === 'AmountDue');
        this.applyWidthStyle = {
            // Directly used in scss
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'margin-left': invoiceNumberCol?.width + 'px',
            width: amountDueCol?.width + 'px'
        };
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        this.appFacadeService.setLoading(true);
        this.subscriptions.push(
            this.getScreenWidth.pipe(debounceTime(1000)).subscribe((width: number) => {
                this.setResponsiveGrid(width);
            })
        );
        this.configService.setMatIconFontFamily();
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config);
            this.logger.logAssert(this.inputs?.customerId, 'customerId is not defined');
            this.customerId = this.inputs?.customerId || '0';
        }
        if (!this.customerId) {
            this.notification.error(this.translateService.instant('customerIdNotConfigured'));
            this.elementLoaded.emit(false);
            return;
        }

        this.subscriptions.push(
            this.store.pipe(select(getLoading)).subscribe((loading: boolean) => {
                this.loading = loading;
                if (loading) {
                    this.appFacadeService.setLoading(true);
                } else {
                    this.appFacadeService.setLoading(false);
                }
            })
        );
        this.subscriptions.push(this.invoiceService.getInvoices(this.customerId, true));
        this.store.dispatch(increasePageNumberInvoices());
        this.elementLoaded.emit(true);
        this.subscriptions.push(
            this.invoices$.subscribe(invoices => {
                if (!isEmpty(invoices)) {
                    this.appOutput.emit(invoices);
                }
            })
        );

        // checking window size
        this.subscriptions.push(
            this.breakpointObserver
                .observe(['(max-width: 992px)'])
                .pipe(
                    tap((value: BreakpointState) => {
                        if (value.matches) {
                            this.filterComponentProps = {
                                searchPlaceHolder: this.translateService.instant('SearchInvoices'),
                                dateLabel: this.translateService.instant('filterbyDate')
                            };
                        } else {
                            this.filterComponentProps = {
                                searchPlaceHolder: this.translateService.instant('QuickSearch'),
                                dateLabel: this.translateService.instant('SelectInvoiceDateRange')
                            };
                        }
                    }),
                    distinctUntilChanged()
                )
                .subscribe()
        );
        this.invoiceTotalAmountDue$ = this.invoiceService.getInvoiceTotalAmountDue(this.customerId).pipe(
            map((data: InvoiceTotalAmountDue[]) => {
                const a: InvoiceTotalAmountDue | null = data.length ? data[0] : null;
                return a?.TotalAmountDue;
            })
        );
        this.uiUrl = this.inputs?.uiUrl;
    }

    filter($e: FilterOutput) {
        this.filters = $e;
        this.invoiceService.filter($e, this.customerId);
    }

    loadMore() {
        if (!this.customerId) {
            this.notification.error(this.translateService.instant('customerIdNotConfigured'));
            return;
        }

        this.store.dispatch(increasePageNumberInvoices());
        this.subscriptions.push(this.invoiceService.getInvoices(this.customerId, false, this.filters?.dates?.startDate, this.filters?.dates?.endDate, this.filters?.search));
        this.gridApi.refreshCells();
    }

    exportCSV() {
        const params: CsvExportParams = {
            skipHeader: false,
            skipGroups: true,
            fileName: 'Invoices.csv',
            processCellCallback: (param: ProcessCellForExportParams) => {
                return this.processCellCSV(param);
            }
        };
        this.gridApi.exportDataAsCsv(params);
    }

    processCellCSV(params: ProcessCellForExportParams): string {
        // Always use CONSTANT_CASE
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const DATE_NODES_INDEX: number[] = [3];
        if (params.accumulatedRowIndex && DATE_NODES_INDEX.includes(params.accumulatedRowIndex)) {
            return this.datePipe.transform(params.value);
        }
        return params.value;
    }

    setResponsiveGrid(width: number) {
        this.gridReady$.next(false);
        if (width > 1024) {
            this.columnDefs = [
                {
                    field: 'InvoiceNumber',
                    headerName: this.translateService.instant('invoiceNumber'),
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header',
                    sortable: true
                },
                {
                    field: 'AmountDue',
                    headerName: this.translateService.instant('amountDue'),
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header',
                    cellRenderer: AmountRendererComponent,
                    sortable: true
                },
                {
                    field: 'GrandTotal',
                    headerName: this.translateService.instant('invoiceTotal'),
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header',
                    cellRenderer: AmountRendererComponent,
                    sortable: true
                },
                {
                    field: 'InvoiceDate',
                    cellRenderer: this.formatDate,
                    headerName: this.translateService.instant('invoiceDate'),
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header',
                    sortable: true
                }
            ];
            this.headerHeight = 35;
            this.isFullWidth = false;
            this.rowHeight = 65;
        }
        if (width < 1024) {
            this.columnDefs = [];
            this.headerHeight = 0;
            this.isFullWidth = true;
            this.rowHeight = 220;
        }
        this.gridReady$.next(true);
    }

    loadContractDetailModal(contractId: string) {
        this.appFacadeService.setLoading(true);

        this.subscriptions.push(
            this.contractService
                .getContractsDetails(this.customerId, contractId)
                .pipe(
                    tap(data => {
                        this.contractDetail = data;
                        this.isDetailPageOpened = true;
                        this.modal.open('contract-detail-page');
                        this.appFacadeService.setLoading(false);
                    })
                )
                .subscribe()
        );
    }

    onCellClicked(event: CellClickedEvent) {
        this.loadInvoiceDetailModal(event.data);
    }

    loadInvoiceDetailModal(data: Invoice) {
        this.invoiceDetail = data;
        this.isInvoiceDetailPageOpened = true;
        this.modal.open('invoice-detail-page');
    }
}
