import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  customerFeatureKey,
  CustomerState,
} from '../reducers/customer-reducer';

export const customerSelector = createFeatureSelector<CustomerState>(
  customerFeatureKey
);

export const getCustomer = createSelector(
  customerSelector,
  (state: CustomerState) => state.information
);

export const getCustomerSummary = createSelector(
  customerSelector,
  (state: CustomerState) => state.customerSummary
);
