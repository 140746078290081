import {
    loadContractAction,
    setLoading,
    setNoMoreRecord,
    clearContractinStore,
    increasePageNumberContract,
    openContract,
    setSelectedContracts,
    unsetSelectedContracts,
    resetContractsLatestToShow,
    resetPageNumberContract
} from '../actions/contract.actions';
import { createReducer, on } from '@ngrx/store';
import { ContractCP } from '../../models/contract-model';

export const contractsFeatureKey = 'contracts';

export interface ContractState {
    pageNumber: number;
    contracts: ContractCP[];
    selectedContracts: string[];
    loading: boolean;
    noMoreRecord: boolean;
    selectedContractId: string;
}

export const initialState: ContractState = {
    pageNumber: 1,
    contracts: [],
    selectedContracts: [],
    loading: false,
    noMoreRecord: false,
    selectedContractId: ''
};

export const contractReducer = createReducer(
    initialState,
    on(loadContractAction, (state: ContractState, { contracts }: { contracts: ContractCP[] }) => {
        if (contracts && contracts.length > 0) {
            // Mark the latest 10 contracts as `latestToShow = true`
            const latestContracts: ContractCP[] = contracts.slice(0, 10).map(contract => ({
                ...contract,
                latestToShow: true
            }));

            const filteredContractsWithLatestFlagReset = state.contracts.filter(contract => !latestContracts.some(latestContract => latestContract.contractId === contract.contractId));
            // Append the remaining contracts without the `latestToShow` flag
            const remainingContracts: ContractCP[] = contracts.slice(10).map(contract => ({
                ...contract,
                latestToShow: false
            }));

            return {
                ...state,
                contracts: [...filteredContractsWithLatestFlagReset, ...latestContracts, ...remainingContracts]
            };
        }
        return state;
    }),
    on(setLoading, (state: ContractState, { loading }: { loading: boolean }) => {
        return { ...state, loading };
    }),
    on(setNoMoreRecord, (state: ContractState, { record }: { record: boolean }) => {
        return { ...state, noMoreRecord: record };
    }),
    on(clearContractinStore, (state: ContractState) => {
        return { ...state, contracts: [], pageNumber: 1 };
    }),
    on(increasePageNumberContract, (state: ContractState) => {
        return { ...state, pageNumber: state?.pageNumber + 1 };
    }),
    on(resetPageNumberContract, (state: ContractState) => {
        return { ...state, pageNumber: 1 };
    }),
    on(openContract, (state: ContractState, { selectedContractId }: { selectedContractId: string }) => {
        return { ...state, selectedContractId };
    }),
    on(setSelectedContracts, (state: ContractState, { selectedContracts }: { selectedContracts: string[] }) => {
        return { ...state, selectedContracts };
    }),
    on(unsetSelectedContracts, (state: ContractState, { unselectContracts }) => ({
        ...state,
        selectedContracts: state.selectedContracts.filter(contractId => !unselectContracts.includes(contractId))
    })),
    on(resetContractsLatestToShow, (state: ContractState) => ({
        ...state,
        contracts: state.contracts.map(contract => ({
            ...contract,
            latestToShow: false
        }))
    }))
);
