import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fetchInvoices } from '../store/actions/invoices.actions';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { FilterOutput } from '../shared/models/filters.model';
import isEmpty from 'lodash-es/isEmpty';
import { InvoiceTotalAmountDue, Invoice } from '../models/invoice-model';

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {
    constructor(private readonly store: Store, private readonly consumerPortalApi: ConsumerPortalApiService) {}

    getInvoices(customerId: number | string, clearPreviousRecords?: boolean, startDate?: string, endDate?: string, search?: string) {
        this.store.dispatch(fetchInvoices({ customerId, clearPreviousRecords, startDate, endDate, search }));
    }

    getInvoicesRecords(customerId: number | string, pageNumber: number = 1, startDate?: string | undefined, endDate?: string | undefined, search?: string | undefined) {
        const url = `customer/${customerId}/invoices?`;
        let headers = {};
        if (pageNumber && customerId) {
            headers = {
                /* eslint-disable @typescript-eslint/naming-convention */
                /**
                 * Note camelCase : DB Model
                 */
                ...headers,
                'x-paging': JSON.stringify({
                    page: pageNumber,
                    pageSize: 10
                })
            };
        }
        const filters = [];
        if (startDate) {
            filters.push({ field: 'InvoiceDate', type: '>=', value: startDate + ' 00:00:01' });
        }
        if (endDate) {
            filters.push({ field: 'InvoiceDate', type: '<=', value: endDate + ' 11:59:59' });
        }
        if (search && !isEmpty(search)) {
            filters.push({ field: 'InvoiceNumber', type: 'LIKE', value: search });
        }
        if (filters.length > 0) {
            headers = {
                ...headers,
                'x-filter': JSON.stringify(filters)
            };
        }
        return this.consumerPortalApi.get<Invoice>(url, { headers: headers });
    }

    filter($e: FilterOutput, customerId: string) {
        const startDate: string | undefined = !isEmpty($e.dates.startDate) ? $e.dates.startDate : undefined;
        const endDate: string | undefined = !isEmpty($e.dates.endDate) ? $e.dates.endDate : undefined;
        this.getInvoices(customerId, true, startDate, endDate, $e.search);
    }

    getInvoiceTotalAmountDue(customerId: number | string): Observable<InvoiceTotalAmountDue[]> {
        const url = `customer/${customerId}/invoices/total-due`;
        return this.consumerPortalApi.get(url);
    }
}
