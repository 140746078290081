import { itemsOutLoading, noRecordFoundItemsOut, clearItemsOut, updateRow } from './../actions/items-out-action.actions';
import { createReducer, on } from '@ngrx/store';
import { loadItemOuts } from '../actions/items-out-action.actions';
import { ItemOutCP } from '../../models/item-out-model';

export const itemsOutFeatureKey = 'itemsOut';

export interface ItemsOutState {
    pageNumber: number;
    items: ItemOutCP[];
    loading: boolean;
    noMoreRecord: boolean;
}

export const initialState: ItemsOutState = {
    pageNumber: 1,
    items: [],
    loading: false,
    noMoreRecord: false
};

export const itemsOutReducer = createReducer(
    initialState,
    on(loadItemOuts, (state: ItemsOutState, { items }: { items: ItemOutCP[] }) => {
        return {
            ...state,
            items: [...state.items, ...items],
            pageNumber: state.pageNumber + 1
        };
    }),
    on(itemsOutLoading, (state: ItemsOutState, { loading }: { loading: boolean }) => {
        return {
            ...state,
            loading
        };
    }),
    on(noRecordFoundItemsOut, (state: ItemsOutState, { loading }: { loading: boolean }) => {
        return {
            ...state,
            noMoreRecord: loading
        };
    }),
    on(clearItemsOut, (state: ItemsOutState) => {
        return {
            ...state,
            items: [],
            pageNumber: 1
        };
    }),
    on(updateRow, (state: ItemsOutState, { nodeId, colId, value }) => {
        const getDraftRowData = function (stateParam: ItemsOutState) {
            return stateParam.items.map((item: ItemOutCP) => {
                if (item.id === nodeId) {
                    return {
                        ...item,
                        [colId]: value
                    };
                }
                return item;
            });
        };
        const draftRowData = getDraftRowData(state);
        return {
            ...state,
            items: draftRowData,
            pageNumber: state.pageNumber
        };
    })
);
