import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, Subscription, throwError } from 'rxjs';
import { VersionToggleService } from './version-toggle';
import { ErrorDailogBoxComponent } from '../shared/components/error-dailog-box/error-dailog-box.component';
import { RequestBody } from '../shared/models/request-body.interface';
import { ErrorType } from '../models/error.model';
import { AuthUiService } from './auth-ui.service';
import { ErrorService } from './error.service';
import { AppFacadeService } from './app-facade.service';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
    constructor(
        private readonly versionToggle: VersionToggleService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly appRef: ApplicationRef,
        private readonly injector: Injector,
        private readonly errorService: ErrorService,
        private readonly auth: AuthUiService,
        private readonly appfacade: AppFacadeService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                const isAuthError = [401].includes(error.status);
                const isInternalError = [500, 502, 404, 403].includes(error.status);
                const isRefreshTokenError = error?.url?.includes('tokens/refresh');
                const isNetworkError = error.status === 0;
                const isOffline = !navigator.onLine;

                if (([401, 403].includes(error.status) || (!error?.status && isRefreshTokenError)) && !this.versionToggle.isQuickLinkVersion()) {
                    this.errorService.setError(ErrorType.AuthFailed);
                    this.auth.logout(); // Trigger logout after retry
                } else if (isAuthError && this.versionToggle.isQuickLinkVersion()) {
                    window.location.reload();
                } else if ((isInternalError || !isAuthError) && this.versionToggle.isQuickLinkVersion()) {
                    const dialogConfig = this.createDialogConfig(request.body as RequestBody, error.status.toString());
                    this.showDialog(dialogConfig);
                } else if ((isNetworkError || isOffline) && this.versionToggle.isQuickLinkVersion()) {
                    const dialogConfig = this.createDialogConfig(request.body as RequestBody, error.status.toString());
                    this.showDialog(dialogConfig);
                }
                this.appfacade.setLoading(false);
                return throwError(() => error);
            })
        );
    }

    private createDialogConfig(
        body: RequestBody,
        errorStatus: string
    ): {
        errormessage: string;
        quickLink: string;
        orgId: string;
        customerId: string;
        date: string;
        contractId: string;
        errorStatus: string;
    } {
        return {
            errormessage: 'An error occurred while processing your request',
            quickLink: window.location.href,
            orgId: body.OrganizationId || '',
            customerId: body.CustomerId || '',
            date: new Date().toString(),
            contractId: body.ContractId || '',
            errorStatus: errorStatus || ''
        };
    }

    private showDialog(config: { errormessage: string; quickLink: string; orgId: string; customerId: string; date: string; contractId: string; errorStatus: string }): void {
        // Create the component manually
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ErrorDailogBoxComponent);
        const componentRef = componentFactory.create(this.injector);

        componentRef.instance.data = config;
        // Use object destructuring to set properties
        Object.assign(componentRef.instance, config);

        const retrySubscription: Subscription = componentRef.instance.retry.subscribe(() => {
            window.location.reload(); // Reload the window on Retry
        });

        // Unsubscribe when done to prevent memory leaks
        retrySubscription.add(() => {
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
            retrySubscription.unsubscribe();
        });

        // Attach the component to the application
        this.appRef.attachView(componentRef.hostView);

        // Append the component to the body
        const domElem = (componentRef.hostView as EmbeddedViewRef<ErrorDailogBoxComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }
}
