import { ConsumerLoginConfig } from '../lib/models/consumer-login-config';

export const inputDefaults: ConsumerLoginConfig = {
    organizationId: '',
    logoUrl: '/assets/images/ecom-default-logo.svg',
    faviconUrl: '/assets/images/ecom-default-logo.svg',
    styling: {
        themeColors: {
            primaryColor: '#0c75b9',
            secondaryColor: '#82be42'
        },
        busyIndicator: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        changePassword: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        forgotPassword: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        signin: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        signup: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        verify: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        }
    }
};
