<div class="redirection-wrapper por">
    <ng-container *ngIf="loadingRecords; else customersSelection">
        <por-busy-indicator [message]="message"></por-busy-indicator>
    </ng-container>

    <ng-template #customersSelection>
        <div class="notifier-wrapper por" *ngIf="consumerAction && !loadingRecords">
            <div class="no-data-found" *ngIf="isError">
                <ng-container [ngSwitch]="consumerAction || !featureService.isAvailable('MultipleCustomerSelection')">
                    <h3 *ngSwitchCase="'Not_Exists'">{{ 'Rental Store will contact you.' | translate }}</h3>
                    <h3 *ngSwitchCase="'NetworkError'">{{ 'NetworkError' | translate }}</h3>
                    <h3 *ngSwitchDefault>{{ 'PoRSupportText' | translate }}</h3>
                    <p *ngIf="errorMessage">{{ errorMessage }}</p>
                </ng-container>
                <por-base-button (onclick)="logout.emit(true)" *ngIf="featureService.isAvailable('LogOff')">{{ 'Logout' | translate }}</por-base-button>
            </div>

            <div class="rms-found-many" *ngIf="consumerAction === 'RMB_Multiple' && featureService.isAvailable('MultipleCustomerSelection')">
                <h3>
                    {{ 'Your E-Mail address is associated with Multiple Customer Records.' | translate }}
                </h3>
                <p>
                    {{ 'Please select the link you wish to link to in this session.' | translate }}
                </p>
                <div class="wrap">
                    <ul>
                        <ng-container *ngFor="let contact of contacts">
                            <li
                                (click)="setUser(contact)"
                                [ngClass]="{
                                    active: contact?.customerId === selectedContact?.customerId
                                }"
                                porAddFeature
                                featureName="consumer-select-button-{{ contact?.customerId }}">
                                <p>
                                    {{ contact?.customerName | uppercase }}
                                </p>
                            </li>
                        </ng-container>
                    </ul>

                    <div class="btn-wrap">
                        <por-base-button type="submit" theme="primary" (click)="openConsumerPortal()" [disabled]="confirmButtonDisabled | convertBoolean" [featureName]="'multi-customer-btn-confirm'">
                            {{ 'Confirm' | translate }}
                        </por-base-button>
                        <por-base-button type="submit" theme="secondary" (click)="signOut(true)" *ngIf="featureService.isAvailable('logOff')" [featureName]="'multi-customer-btn-logout'">
                            {{ 'Logout' | translate }}
                        </por-base-button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
