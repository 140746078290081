import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { setNoMoreRecordInvoices, loadInvoicesAction, clearInvoicesinStore, setInvoicesLoading, increasePageNumberInvoices } from '../store/actions/invoices.actions';
import { getPageNumberInvoices } from '../store/selector/invoices.selectors';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { FilterOutput } from '../shared/models/filters.model';
import isEmpty from 'lodash-es/isEmpty';
import { InvoiceTotalAmountDue, Invoice } from '../models/invoice-model';

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {
    constructor(private readonly store: Store, private readonly consumerPortalApi: ConsumerPortalApiService) {}

    getInvoices(customerId: number | string, clearPreviousRecords?: boolean, startDate?: string, endDate?: string, search?: string): Subscription {
        if (clearPreviousRecords) {
            this.store.dispatch(clearInvoicesinStore());
        }
        return this.store.pipe(select(getPageNumberInvoices), take(1)).subscribe((pageNumber: number) => {
            this.store.dispatch(setInvoicesLoading({ loading: true }));
            this.getInvoicesRecords(customerId, pageNumber, startDate, endDate, search)
                .pipe(
                    map(items => {
                        if (items.length <= 0) {
                            this.store.dispatch(setNoMoreRecordInvoices({ record: true }));
                            this.store.dispatch(setInvoicesLoading({ loading: false }));
                        }

                        if (items.length > 0) {
                            this.store.dispatch(setNoMoreRecordInvoices({ record: false }));
                            this.store.dispatch(loadInvoicesAction({ invoices: items as Invoice[] }));
                            this.store.dispatch(setInvoicesLoading({ loading: false }));
                            if (!clearPreviousRecords) {
                                this.store.dispatch(increasePageNumberInvoices());
                            }
                        }
                    }),
                    catchError(async () => {
                        this.store.dispatch(setNoMoreRecordInvoices({ record: true }));
                        this.store.dispatch(setInvoicesLoading({ loading: false }));
                    })
                )
                .pipe(take(1))
                .subscribe();
        });
    }

    getInvoicesRecords(customerId: number | string, pageNumber: number = 1, startDate?: string | undefined, endDate?: string | undefined, search?: string | undefined) {
        const url = `customer/${customerId}/invoices?`;
        let headers = {};
        if (pageNumber && customerId) {
            headers = {
                /* eslint-disable @typescript-eslint/naming-convention */
                /**
                 * Note camelCase : DB Model
                 */
                ...headers,
                'x-paging': JSON.stringify({
                    page: pageNumber,
                    pageSize: 10
                })
            };
        }
        const filters = [];
        if (startDate) {
            filters.push({ field: 'InvoiceDate', type: '>=', value: startDate + ' 00:00:01' });
        }
        if (endDate) {
            filters.push({ field: 'InvoiceDate', type: '<=', value: endDate + ' 11:59:59' });
        }
        if (search && !isEmpty(search)) {
            filters.push({ field: 'InvoiceNumber', type: 'LIKE', value: search });
        }
        if (filters.length > 0) {
            headers = {
                ...headers,
                'x-filter': JSON.stringify(filters)
            };
        }
        return this.consumerPortalApi.get(url, { headers: headers });
    }

    filter($e: FilterOutput, customerId: string) {
        const startDate: string | undefined = !isEmpty($e.dates.startDate) ? $e.dates.startDate : undefined;
        const endDate: string | undefined = !isEmpty($e.dates.endDate) ? $e.dates.endDate : undefined;
        this.getInvoices(customerId, true, startDate, endDate, $e.search);
    }

    getInvoiceTotalAmountDue(customerId: number | string): Observable<InvoiceTotalAmountDue[]> {
        const url = `customer/${customerId}/invoices/total-due`;
        return this.consumerPortalApi.get(url);
    }
}
