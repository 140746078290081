<ng-container [ngSwitch]="featureToggleService.isAvailable('canHaveProductsKeyDocumentsView')">
    <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="productDetailDocumentsTemplate"></ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="productDetailNormalTemplate"></ng-container>
</ng-container>

<ng-template #productDetailNormalTemplate>
    <div class="product-details-page">
        <div class="header">
            <h1 class="theme-heading">{{ 'ProductDetails' | translate }}</h1>
            <por-base-button
                (click)="closePanel.emit(true)"
                icon="close"
                featureName="product-detail-close-button"
                *ngIf="!appFacadeService.versionToggleService.isStockQuickLinkVersion()"></por-base-button>
        </div>

        <div class="wrapper-product">
            <div class="content">
                <ng-container [ngTemplateOutlet]="documentsInformation"></ng-container>
            </div>
            <div class="links" *ngIf="isContentAvailable('OtherUrls')">
                <h3 class="title">{{ 'Documents' | translate }}</h3>
                <a *ngFor="let link of productDetail?.OtherUrls" [href]="link" target="_blank">
                    <img src="{{ uiUrl }}/assets/images/noun-pdf-file-5441350Gray.svg" />
                </a>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #productDetailDocumentsTemplate>
    <div class="product-details-page document-template">
        <div class="top-bar" *ngIf="!isMobileLayout">
            <h1 class="theme-heading">{{ 'ProductDetails' | translate }}</h1>
        </div>
        <div class="header">
            <h1 *ngIf="isContentAvailable('Name')" class="title">
                {{ productDetail?.Name }}
                <span *ngIf="isContentAvailable('SerialNumber')" [ngClass]="{ 'd-flex': isMobileLayout }">
                    {{ productDetail?.SerialNumber }}
                </span>
            </h1>
            <por-base-button
                (click)="closePanel.emit(true)"
                icon="close"
                featureName="product-detail-close-button"
                *ngIf="!appFacadeService.versionToggleService.isStockQuickLinkVersion()"></por-base-button>
        </div>
        <div class="sub-header">
            <h3>{{ 'Documents' | translate }}</h3>
        </div>
        <div class="wrapper-product">
            <div class="content">
                <div class="cols d-flex">
                    <div class="col-50" [ngClass]="{ 'no-product-data': !isContentAvailable('Images') && !isContentAvailable('StoredFiles') && !isContentAvailable('Description') }">
                        <ng-container [ngTemplateOutlet]="documentsList"></ng-container>
                    </div>
                    <div class="col-50" *ngIf="isContentAvailable('Images') || isContentAvailable('StoredFiles') || isContentAvailable('Description')">
                        <ng-container [ngTemplateOutlet]="documentsInformation"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #documentsList>
    <div class="documents">
        <ul [ngClass]="{ 'no-border': appFacadeService.versionToggleService.isStockQuickLinkVersion() }">
            <li class="text-align-center" *ngIf="featureToggleService.isAvailable('displayInspectionDocument')">
                <button
                    class="btn-link d-inline-flex button-loading-outer"
                    [disabled]="'InspectionCertificate' | isDocApiCalled | async"
                    [style.cursor]="('InspectionCertificate' | isDocApiCalled | async) ? 'default' : 'pointer'"
                    porAddFeature
                    [featureName]="'inspection-certificate-btn'"
                    (click)="openDocumentUrl('InspectionCertificate')">
                    <img class="actions-image" src="{{ uiUrl }}/assets/images/inspection-certificate-150245.svg" porAddFeature featureName="'inspection-certificate-btn'" />
                    <label porAddFeature featureName="'inspection-certificate-btn'">{{ 'InspectionCertifications' | translate }}</label>
                    <div class="button-loading" *ngIf="'InspectionCertificate' | getdocument | async">
                        <img src="./assets/images/output-onlinegiftools.gif" alt="" />
                    </div>
                </button>
            </li>
            <li class="text-align-center" *ngIf="featureToggleService.isAvailable('displayServiceCertificate')">
                <button
                    class="btn-link d-inline-flex button-loading-outer"
                    [disabled]="'ServiceCertificate' | isDocApiCalled | async"
                    [style.cursor]="('ServiceCertificate' | isDocApiCalled | async) ? 'default' : 'pointer'"
                    porAddFeature
                    [featureName]="'service-certificate-btn'"
                    (click)="openDocumentUrl('ServiceCertificate')">
                    <img class="actions-image" src="{{ uiUrl }}/assets/images/service-certificate-3504413.svg" porAddFeature featureName="'service-certificate-btn'" />
                    <label porAddFeature featureName="'service-certificate-btn'">{{ 'ServiceCertificates' | translate }}</label>
                    <div class="button-loading" *ngIf="'ServiceCertificate' | getdocument | async">
                        <img src="./assets/images/output-onlinegiftools.gif" alt="" />
                    </div>
                </button>
            </li>
            <li class="text-align-center">
                <button
                    class="btn-link d-inline-flex button-loading-outer"
                    [disabled]="'SafetyInstruction' | isDocApiCalled | async"
                    [style.cursor]="('SafetyInstruction' | isDocApiCalled | async) ? 'default' : 'pointer'"
                    porAddFeature
                    [featureName]="'instructions-btn'"
                    (click)="openDocumentUrl('SafetyInstruction')">
                    <img class="actions-image" src="{{ uiUrl }}/assets/images/instructions.svg" porAddFeature featureName="'instructions-btn'" />
                    <label porAddFeature featureName="'instructions-btn'">{{ 'instructions' | translate }}</label>
                    <div class="button-loading" *ngIf="'SafetyInstruction' | getdocument | async">
                        <img src="./assets/images/output-onlinegiftools.gif" alt="" />
                    </div>
                </button>
            </li>
        </ul>
        <ul *ngIf="!appFacadeService.versionToggleService.isStockQuickLinkVersion()">
            <li class="text-align-center">
                <button class="btn-link d-inline-flex" porAddFeature featureName="send-taglink-btn" (click)="openModal()">
                    <img class="actions-image" src="{{ uiUrl }}/assets/images/mail-signal-1684972-1442449.svg" porAddFeature featureName="send-taglink-btn" />
                    <label porAddFeature featureName="send-taglink-btn">{{ 'SendDocument' | translate }}</label>
                </button>
            </li>
        </ul>
    </div>
</ng-template>
<ng-template #documentsInformation>
    <div class="image-section" *ngIf="isContentAvailable('Images')">
        <img [src]="productDetail?.Images?.[0]?.['Uri']" [alt]="productDetail?.Images?.[0]?.['AltText']" />
    </div>
    <div class="image-section" *ngIf="isContentAvailable('StoredFiles')">
        <img [src]="productDetail?.StoredFiles?.[0]?.['PublicURL']" />
    </div>
    <div class="description" *ngIf="productDetail">
        <h3 *ngIf="isContentAvailable('Name') && !featureToggleService.isAvailable('canHaveProductsKeyDocumentsView')" class="title">{{ productDetail.Name }}</h3>
        <span *ngIf="isContentAvailable('Description')">{{ productDetail.Description }}</span>
    </div>
</ng-template>
<por-base-modal [id]="'product-detail-modal'" [modalWidth]="'modal-lg'">
    <por-send-document-link
        [sendLinkRequestData]="sendLinkRequestData"
        (isFormReturn)="handleRentalFormReturn()"
        (sendLinkRequestInfo)="handleSendDocumentFormSubmission($event)"></por-send-document-link>
</por-base-modal>
