import { createAction, props } from '@ngrx/store';
import { ItemOutCP } from '../../models/item-out-model';

export const loadItemOuts = createAction(
  '[ItemsOutAction] Load ItemsOutActions',
  props<{ items: ItemOutCP[] }>()
);

export const itemsOutLoading = createAction(
  '[ItemsOutAction] Set Items Loading ItemsOut',
  props<{ loading: boolean }>()
);

export const noRecordFoundItemsOut = createAction(
  '[ItemsOutAction] Set No Record Found ItemsOut',
  props<{ loading: boolean }>()
);

export const clearItemsOut = createAction('[ItemsOutAction] Clear ItemsOut');

export const updateRow = createAction(
    '[ItemsOutAction] UPDATE_ROW',
    props<{ nodeId:string; colId:string; value:string | Date }>()
);

