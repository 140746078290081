import { Pipe, PipeTransform } from '@angular/core';
import { AppFacadeService } from '../services/app-facade.service';
import { DocumentData } from '../store/reducers/documents.reducer';
import { Observable, map } from 'rxjs';

@Pipe({
    name: 'isDocApiCalled'
})
export class IsDocApiCalledPipe implements PipeTransform {
    constructor(private readonly appFacadeService: AppFacadeService) {}
    transform(documentType: string): Observable<boolean> {
        return this.appFacadeService.getPdfdocument(documentType).pipe(
            map((response: DocumentData[]) => {
                if (response.length > 0) {
                    return !(response[0]?.isApiCalled && response[0]?.isURLFound);
                }
                return true;
            })
        );
    }
}
