import { Component, ContentChildren, QueryList, AfterContentInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { AccordionGroupComponent } from './accordion-group.component';

@Component({
    selector: 'por-accordion',
    template: `
        <div class="accordion"><ng-content></ng-content></div>
    `,
    styleUrls: ['./accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent implements AfterContentInit {
    @ContentChildren(AccordionGroupComponent)
    groups!: QueryList<AccordionGroupComponent>;
    @Input() defaultOpenedIndex = 0;

    ngAfterContentInit(): void {
        if (this.groups && this.groups.length > 0) {
            this.groups.toArray()[this.defaultOpenedIndex].opened = true;
        }
    }

    openGroup(group: AccordionGroupComponent): void {
        // close other groups
        this.groups.toArray().forEach(t => (t.opened = false));
        // open current group
        group.opened = true;
    }
}
