import { Component, Inject, Input, LOCALE_ID, ChangeDetectionStrategy } from '@angular/core';
import { ContractDetail } from '../../../models/contract-model';
import { DateFormatType } from '../../../enums/date-format-type.enum';
import { DateUtility } from '../../../date.utility';
import { Consumed } from '../../../enums/consumed-payment-verify';
import { PaymentMethodEnums } from '../../../enums/payment-method.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'por-contract-payment-history-modal',
    templateUrl: './contract-payment-history-modal.component.html',
    styleUrls: ['./contract-payment-history-modal.component.scss']
})
export class ContractPaymentHistoryModalComponent {
    @Input() contractDetail!: ContractDetail[] | null;

    dateFormat: string;
    consumed = Consumed;

    constructor(@Inject(LOCALE_ID) locale: string, private readonly translateService: TranslateService) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    isPaymentMethodAvailable() {
        return this.contractDetail?.some(x => x.Payment?.some(y => y.PaymentMethod));
    }

    getPaymentMethod(paymentMethod: PaymentMethodEnums) {
        switch (paymentMethod) {
            case PaymentMethodEnums.CreditCard:
                return this.translateService.instant('PaymentMethod.CreditCard');
            case PaymentMethodEnums.DebitCard:
                return this.translateService.instant('PaymentMethod.DebitCard');
            case PaymentMethodEnums.GiftCard:
                return this.translateService.instant('PaymentMethod.GiftCard');
            case PaymentMethodEnums.ACH:
                return this.translateService.instant('PaymentMethod.ACH');
            case PaymentMethodEnums.Crypto:
                return this.translateService.instant('PaymentMethod.Crypto');
            default:
                return '';
        }
    }
}
