import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ConsumerLoginComponent } from './consumer-login.component/consumer-login.component';
import { ConsumerLoginApiService } from './services/consumer-login-api.service';
import { SignupComponentComponent } from './signup-component/signup-component.component';
import { SigninComponentComponent } from './signin-component/signin-component.component';
import { createCustomElement } from '@angular/elements';
import { VerifyComponentComponent } from './verify-component/verify-component.component';
import { AlertComponentComponent } from './shared/alert-component/alert-component.component';
import { LoaderComponentComponent } from './shared/loader-component/loader-component.component';
import { ForgotPasswordComponentComponent } from './forgot-password-component/forgot-password-component.component';
import { ChangePasswordComponentComponent } from './change-password-component/change-password-component.component';
import { BusyIndicatorComponentComponent } from './busy-indicator-component/busy-indicator-component.component';
import { TranslateLoader, TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { UIMissingTranslationHandler, UITranslateLoader } from '@por/shared/ui/translations';
import * as EnUSTranslations from '../assets/en-US.json';
import * as EnGBTranslations from '../assets/en-GB.json';
import { Language } from '@por/shared/core';
import { ApxButtonModule, ApxFormsModule, ApxIconModule, ApxInputModule } from '@por/apex';
import { SvgIconComponent } from './shared/icon-component/icon-component.component';
import { ConsumerPortalDemoModule } from '@por/consumer-portal-demo';
import { ApxIconCheck, ApxIconEmail, ApxIconLock, ApxIconMagnifier, ApxIconSpinner } from '@por/apex/icons';
import { AddFeatureClassDirective } from './shared/directive/add-feature-class.directive';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ApxButtonModule,
    ApxFormsModule,
    ApxInputModule,
    ApxIconModule.forRoot([ApxIconCheck,ApxIconEmail,ApxIconLock,ApxIconSpinner, ApxIconMagnifier]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: () => {
          return new UITranslateLoader(
            { [Language.EN_US]: EnUSTranslations, [Language.EN_GB]: EnGBTranslations }
          );
        },
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: UIMissingTranslationHandler },
      defaultLanguage: Language.EN_US
    }),
    ConsumerPortalDemoModule
  ],
  declarations: [
    ConsumerLoginComponent,
    SignupComponentComponent,
    SigninComponentComponent,
    VerifyComponentComponent,
    AlertComponentComponent,
    LoaderComponentComponent,
    SvgIconComponent,
    ForgotPasswordComponentComponent,
    ChangePasswordComponentComponent,
    BusyIndicatorComponentComponent,
    AddFeatureClassDirective
  ],
  providers: [
    ConsumerLoginApiService,
  ],
  exports: [ConsumerLoginComponent],
  entryComponents: [ConsumerLoginComponent],
})
export class ConsumerLoginModule implements DoBootstrap {
    constructor(private readonly injector: Injector) {}
    ngDoBootstrap(): void {
        const signin = createCustomElement(SigninComponentComponent, {
            injector: this.injector
        });
        customElements.define('por-signin', signin);

        const signup = createCustomElement(SignupComponentComponent, {
            injector: this.injector
        });
        customElements.define('por-signup', signup);
        const verify = createCustomElement(VerifyComponentComponent, {
            injector: this.injector
        });
        customElements.define('por-verify', verify);
    }
}
