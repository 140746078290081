import { createAction, props } from '@ngrx/store';
import { ContractCP } from '../../models/contract-model';

export enum ContractActionTypes {
    LoadContractAction = '[ContractActions] Load ContractActions',
    SetContractLoading = '[ContractActions] Set ContractLoading',
    SetNoMoreRecord = '[ContractActions] Set setNoMoreRecord',
    LoadContractActions = '[ContractActions] Load ContractActions',
    ClearContractInStore = '[ContractActions] Clear ContractinStore',
    ClearIncreasePageNumber = '[ContractActions] Clear increasePageNumber',
    OpenContract = '[ContractActions] Open Contract',
    ContractsSelected = '[ContractActions] Set Selected Contracts',
    ContractsUnSelected = '[ContractActions] Set UnSelected/Remove Contracts from Selected Contracts',
    ResetLatestToShow = '[ContractActions] Reset latestToShow',
    IncreasePageNumber = '[ContractActions] Increase PageNumber'
}

export const loadContractAction = createAction(ContractActionTypes.LoadContractAction, props<{ contracts: ContractCP[] }>());

export const setLoading = createAction(ContractActionTypes.SetContractLoading, props<{ loading: boolean }>());

export const setNoMoreRecord = createAction(ContractActionTypes.SetNoMoreRecord, props<{ record: boolean }>());

export const contractsLoading = createAction(ContractActionTypes.LoadContractActions, props<{ loading: boolean }>());

export const clearContractinStore = createAction(ContractActionTypes.ClearContractInStore);

export const increasePageNumberContract = createAction(ContractActionTypes.IncreasePageNumber);

export const openContract = createAction(ContractActionTypes.OpenContract, props<{ selectedContractId: string }>());

export const setSelectedContracts = createAction(ContractActionTypes.ContractsSelected, props<{ selectedContracts: string[] }>());

export const unsetSelectedContracts = createAction(ContractActionTypes.ContractsUnSelected, props<{ unselectContracts: string[] }>());

export const resetContractsLatestToShow = createAction(ContractActionTypes.ResetLatestToShow);

export const resetPageNumberContract = createAction(ContractActionTypes.ClearIncreasePageNumber);
