<div class="portalnav-wrap por-ac-contracts">
    <div class="wrapper contract-headingSection" *ngIf="!isDetailPageOpened">
        <h2>{{ 'Contract List for' | translate }} {{ customer?.Name }}</h2>
        <span *ngIf="versionToggle.isAdvanceVersion()">{{ 'contractTabInfoText' | translate }}.</span>
    </div>

    <div class="portalnav wrapper" [ngStyle]="{ height: height, width: width }">
        <div class="main-contract-page">
            <div class="contract-wrapper" [ngClass]="{ hide: isDetailPageOpened }">
                <div class="date-range">
                    <por-filters
                        [multiFilters]="filterValues"
                        [initFilters]="initialStatusFilters"
                        [isProcessing]="loading"
                        [searchPlaceholder]="filterComponentProps.searchPlaceHolder"
                        [dateLabel]="filterComponentProps.dateLabel"
                        (filterOutput)="filter($any($event))"
                        [disableSearch]="!isSearchSupported"
                        (exportCSV)="exportCSV()"
                        [enableExport]="$any(contracts$ | async | count) > 0"
                        [uiUrl]="uiUrl"></por-filters>
                </div>
                <div class="content-wrapper" porAddFeature featureName="aggridheader">
                    <span class="click-title theme-paragraph">{{ 'Click a contract# link to view contract details' | translate }}</span>
                    <ag-grid-angular
                        *ngIf="gridReadyObservable$ | async"
                        g-grid-angular
                        class="ag-theme-alpine ag-grid"
                        [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef"
                        [rowData]="contracts$ | async"
                        [animateRows]="true"
                        [rowHeight]="rowHeight"
                        [headerHeight]="headerHeight"
                        (gridReady)="onGridReady($event)"
                        (cellDoubleClicked)="onCellDoubleClicked($event)"
                        [isFullWidthRow]="isFullWidthRow"
                        [fullWidthCellRenderer]="fullWidthCellRenderer"
                        [context]="context"></ag-grid-angular>
                </div>
                <div class="middle">
                    <por-base-button [featureName]="'load-more'" [class]="'theme-button'" *ngIf="(isRecordAvaible$ | async) === false" (onclick)="loadMore()">
                        {{ 'Load More' | translate }}
                    </por-base-button>
                </div>
            </div>

            <div [ngClass]="{ hide: !isDetailPageOpened }" class="contract-detail">
                <por-contract-detail
                    (closePanel)="isDetailPageOpened = false"
                    [customerId]="customerId"
                    *ngIf="isDetailPageOpened"
                    [contractDetail]="contractDetail"
                    [uiUrl]="inputs?.uiUrl"
                    [customerId]="customerId"
                    (reloadContract)="reloadContract($event)"
                    (reloadContractList)="contractlist.next(true)"></por-contract-detail>
            </div>
        </div>
    </div>
</div>
