import { createReducer, on } from '@ngrx/store';
import { emptyDocumentData, loadDocumentFinished } from '../actions/document.actions';

export const documentsFeatureKey = 'docData';
export interface DocState {
    documentData: DocumentData[];
}
export interface DocumentData {
    docType: string;
    url: string;
    isLoading?: boolean;
    isApiCalled: boolean;
    contractId: string;
    orgType: string;
    isURLFound: boolean;
}

export const initialState: DocState = {
    documentData: []
};

export const documentReducer = createReducer(
    initialState,
    on(loadDocumentFinished, (state, { documentData }) => {
        let docData = [...state.documentData];
        docData = [...docData, documentData];
        return {
            ...state,
            documentData: docData
        };
    }),
    on(emptyDocumentData, state => {
        return { ...state, documentData: [] };
    })
);
