<div class="form-container por-signin">
    <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
        <apx-form-field
            label="Email"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="username.invalid && (username.dirty || username.touched) ?
            username.errors?.['required'] ? ('Please enter username.' | translate) :
            username.errors?.['email'] ? ('Please enter valid email.' | translate) :
            username.errors?.['maxlength'] ? ('max_128_chars_permitted' | translate):''
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="email"></span>
                <input
                    apxInput
                    type="text"
                    formControlName="username"
                    type="email"
                    autocomplete="on"
                    name="username"
                    placeholder="{{ 'Enter your email' | translate }}"
                    class="theme-input"
                    porAddFeature
                    featureName="login-email-input" />
                />
            </apx-input-group>
        </apx-form-field>
        <apx-form-field
            label="Password"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="password.invalid && (password.dirty || password.touched) ?
            password.errors?.['required'] ? ('Please enter password.' | translate) :
            password.errors?.['minlength'] ? ('Password minimum length should be 6.' | translate) :
            password.errors?.['maxlength'] ? ('max_50_chars_permitted' | translate):''
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="lock"></span>

                <input
                    apxInput
                    formControlName="password"
                    type="password"
                    name="password"
                    autocomplete="on"
                    placeholder="{{ 'Enter your password' | translate }}"
                    class="theme-input"
                    maxlength="50"
                    porAddFeature
                    featureName="login-password-input" />
                />
            </apx-input-group>
        </apx-form-field>

        <button
            porAddFeature
            featureName="login-button"
            class="btn-primary btn-full-width theme-button"
            type="submit"
            apxButton
            variant="primary"
            [disabled]="loginForm.pristine || loginForm.invalid || isDisableButton">
            {{ 'Login' | translate }}
        </button>
    </form>
    <button
        porAddFeature
        featureName="quick-link-page"
        class="btn-secondary btn-full-width theme-button back-quick-btn"
        type="submit"
        apxButton
        variant="secondary"
        (click)="onBackQL()"
        *ngIf="quickLinkUrl.length > 0">
        {{ 'Back to QuikLink' | translate }}
    </button>
    <button
        porAddFeature
        featureName="quick-link-page"
        class="btn-secondary theme-buttonclose-btn close-btn-mobile"
        type="submit"
        apxButton
        variant="secondary"
        (click)="onBackQL()"
        *ngIf="quickLinkUrl.length > 0">
        X
    </button>
    <p class="left-align theme-paragraph">
        <button porAddFeature featureName="forgot-password-button" class="btn-link theme-anchor" (click)="setTemplate('forgot-password')">
            {{ 'Forgot password?' | translate }}
        </button>
    </p>
    <p class="left-align theme-paragraph">
        {{ "Don't have an account?" | translate }}
        <a porAddFeature featureName="create-account-button" href="#" (click)="setTemplate('signup')" class="theme-anchor btn-link">
            {{ 'Create your account now' | translate }}
        </a>
        ,
        <span>{{ 'it takes less than a minute.' | translate }}</span>
    </p>
</div>
