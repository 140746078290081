import { Observable, map } from 'rxjs';
import { DocumentData } from '../store/reducers/documents.reducer';
import { AppFacadeService } from './../services/app-facade.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getdocument'
})
export class GetdocumentPipe implements PipeTransform {
    constructor(private readonly appFacadeService: AppFacadeService) {}
    transform(documentType: string): Observable<boolean> {
        return this.appFacadeService.getPdfdocument(documentType).pipe(
            map((response: DocumentData[]) => {
                if (response.length > 0) {
                    return !response[0]?.isApiCalled;
                }
                return true;
            })
        );
    }
}
