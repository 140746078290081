<div class="portalnav-wrap por-ac-itemsout">
  <div
    class="portalnav"
    [ngStyle]="{ height: height, width: width }"
  >
  <div class="wrapper contract-headingSection">
    <h1 class="theme-heading">{{ 'Items Out Report' | translate }}</h1>
  </div>
    <div id="mainportalpage" class="wrapper">
      <div class="button-group">
        <por-filters
          [singleFilters]="singleFilters"
          [isProcessing]="loading"
          (filterOutput)="filter($any($event))"
          (getSingleFilter)="filterByStatus($event)"
          [searchPlaceholder]="filterComponentProps.searchPlaceHolder"
          [dateLabel]="filterComponentProps.dateLabel"
          [disableSearch]="!isSearchSupported"
          (exportCSV)="exportCSV()"
          [uiUrl]="uiUrl"
          [enableExport]="$any((itemsOut$ | async) | filterLineItemStatus : lineItemStatusFilter | count) > 0"
          ></por-filters>
      </div>
      <div class="content-wrapper">
        <div class="grid-top-content">
          <por-base-button
            [class]="'theme-button m-order-2'"
            (click)="getSelectedRowData()"
            [disabled]="selectionDisabled | convertBoolean "
            [featureName]="'selection-complete-on-itemout-page'"
          >
            {{ 'SelectionComplete' | translate }}
          </por-base-button>
          <div>
            <p>{{'itemOutInstruction1' | translate}}</p>
            <p>{{'itemOutInstruction2' | translate}}</p>
            <p>{{'itemOutInstruction3' | translate}}</p>
          </div>
        </div>
        <ag-grid-angular
          *ngIf="gridReadyObservable$ | async"
          style="width: 100%; height: 100%"
          class="ag-theme-alpine ag-grid"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="(itemsOut$ | async) | filterLineItemStatus : lineItemStatusFilter"
          [rowHeight]="rowHeight"
          [headerHeight]="headerHeight"
          [animateRows]="true"
          (gridReady)="onGridReady($event)"
          [isRowSelectable]="isRowSelectable"
          [rowSelection]="rowSelection"
          [suppressRowClickSelection]="true"
          [isFullWidthRow]="isFullWidthRow"
          [fullWidthCellRenderer]="fullWidthCellRenderer"
          [context]="context"
          (selectionChanged)="onSelectionChanged()"
          [getRowId]="getRowId"
        ></ag-grid-angular>
      </div>
      <div class="middle">
        <por-base-button
          [featureName]="'load-more-items-out'"
          [class]="'theme-button'"
          *ngIf="(noMoreRecord$ | async) === false"
          (onclick)="loadMore()"
        >
          {{ 'Load More' | translate }}
        </por-base-button>
      </div>
    </div>
  </div>
</div>
<por-base-modal
  [id]="'rental-request-page'"
  [modalWidth]="'modal-lg'"
  (onclose)="onClosingRequestModal()"
>
  <por-rental-request-modal
    [customerId]="customerId"
    [items]="selectedRows"
    [totalItems]="totalRowsSelected"
    [uiUrl]="inputs?.uiUrl"
    (callOffRequestInfo)="handleRentalFormSubmission($event)"
    *ngIf="!selectionDisabled"
  ></por-rental-request-modal>
</por-base-modal>
