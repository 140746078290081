import { PaymentMethodEnums } from '../../enums/payment-method.enum';
import { Searchable } from '../../models/searchable.model';

export interface PaymentResponse {
    data?: [];
}

export interface PaymentData {
    organizationId?: string;
    amount?: number | string;
    contractId?: string;
    referenceNumber?: string;
    currency?: string;
    depotId?: string;
    address?: string;
    companyName?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
}

export interface PaymentMinMaxBoundary extends Searchable {
    paymentMethod: PaymentMethodEnums;
    minAmount: number;
    maxAmount: number;
}
