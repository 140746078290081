import { InjectionToken } from '@angular/core';

/* eslint-disable @typescript-eslint/naming-convention */
export interface ConsumerPortalAdminConfig {
    production?: boolean;
    customerId?: string;
    organizationId: string;
    organizationType: string;
    organizationName?: string;
    apiUrl?: string;
    uiUrl?: string;
    showLoader?: boolean;
    defaultSubDomain?: string; // Required for dev and local environments
    menu?: Partial<Menu>;
    header?: Partial<Header>;
    footer?: Partial<Footer>;
    consumerPortal?: {
        height: string;
        width: string;
    };
    styling?: {
        themeColors?: {
            primaryColor?: string;
            secondaryColor?: string;
        };
        menu?: unknown;
        header?: Styling;
        footer?: Styling;
    };
    Auth?: {
        accessToken?: string;
        AccessToken?: string; // Backward compatibility
        RefreshToken?: string;
        Email?: string;
    };
    enabledFeatures?: EnabledFeatures;
    initialInputConfigAccessToken?: string;
}

export interface EnabledFeatures {
    essentials?: unknown; // Currently the type is unknown because we don't know what all features will be there in essentials Admin.
    syrinx?: unknown; // Currently the type is unknown because we don't know what all features will be there in syrinx Admin.
    expert?: unknown; // Currently the type is unknown because we don't know what all features will be there in expert Admin.
}

export interface Styling {
    paragraph?: unknown;
    heading?: unknown;
    button?: unknown;
    buttonToggle?: unknown;
    table?: unknown;
    anchor?: unknown;
    primaryColor?: unknown;
    secondaryColor?: unknown;
}

export interface Menu {
    Mandatory: boolean;
}

export interface Header {
    headerTitle: string;
    headerTitlePosition: string;
    logoUrl: string;
    logoPosition: string;
    logoWidth: string;
}

export interface Footer {
    footerText: string;
    position: string;
}

export const CONSUMER_PORTAL_ADMIN_APP_CONFIG = new InjectionToken<ConsumerPortalAdminConfig>('Application admin config');
export const CPEnvironment = new InjectionToken('Consumer Portal Admin Environment config');
