import { ContractCP } from '../../models/contract-model';
import { ContractState, contractsFeatureKey } from './../reducers/contracts.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const contractSelector = createFeatureSelector<ContractState>(contractsFeatureKey);

export const getPageNumber = createSelector(contractSelector, (state: ContractState) => state.pageNumber);

export const getContracts = createSelector(contractSelector, (state: ContractState) => state.contracts.filter(contract => contract.latestToShow));

export const getLoading = createSelector(contractSelector, (state: ContractState) => state.loading);

export const getNoMoreRecord = createSelector(contractSelector, (state: ContractState) => state.noMoreRecord);

export const getSelected = createSelector(contractSelector, (state: ContractState) => state.selectedContractId);

export const getSelectedContracts = createSelector(contractSelector, (state: ContractState) => state.selectedContracts);

const isCreditContract = (contract: ContractCP): boolean => contract.contractId.startsWith('c');

export const getContractsTotal = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) => {
    let total = 0;

    state.contracts
        .filter((contract: ContractCP) => selectedContracts.includes(contract.contractId))
        .forEach((contract: ContractCP) => {
            if (isCreditContract(contract)) {
                total -= Math.abs(Number(contract.AmountDue));
            } else {
                total += Number(contract.AmountDue);
            }
        });

    return total;
});

export const getCreditContractsTotal = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) => {
    let total = 0;

    state.contracts
        .filter((contract: ContractCP) => selectedContracts.includes(contract.contractId))
        .forEach((contract: ContractCP) => {
            if (isCreditContract(contract)) {
                total -= Math.abs(Number(contract.AmountDue));
            }
        });

    return total;
});

export const getSelectedContractsTotal = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) => {
    let total = 0;

    state.contracts
        .filter((contract: ContractCP) => selectedContracts.includes(contract.contractId))
        .forEach((contract: ContractCP) => {
            if (!isCreditContract(contract)) {
                total += Number(contract.AmountDue);
            }
        });

    return total;
});

export const getSelectedCreditContracts = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) =>
    state.contracts.filter((contract: ContractCP) => selectedContracts.includes(contract.contractId) && isCreditContract(contract))
);

export const getSelectedNonCreditContracts = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) =>
    state.contracts.filter((contract: ContractCP) => selectedContracts.includes(contract.contractId) && !isCreditContract(contract))
);

export const isContractSelected = (contractId: string) =>
    createSelector(getSelectedContracts, (selectedContracts: string[]) => {
        return selectedContracts.includes(contractId);
    });

export const isContractBelongsToSameDepot = (contractId: string) =>
    createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) => {
        const selectedContract = state.contracts.find((contract: ContractCP) => selectedContracts.includes(contract.contractId));
        const contracts = state.contracts.find((contract: ContractCP) => contract.contractId === contractId);
        if (selectedContract && contracts) {
            return selectedContract.depotId !== contracts.depotId;
        }

        return false;
    });
