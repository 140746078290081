import { DoBootstrap, Injector, NgModule, LOCALE_ID } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { CustomerPortalUiModule, CustomerPortalComponent, CONSUMER_PORTAL_APP_CONFIG, CONSUMER_PORTAL_APP_LANG, CPEnvironment, DEFAULT_LOCALE } from '@por/consumer-portal/ui-lib';
import { CONSUMER_LOGIN_APP_CONFIG } from '@por/consumer-login/ui-lib';
import { environment } from '../environments/environment';
import { Language } from '@por/shared/core';
import { DEMO_ENV_CONFIG } from '@por/consumer-portal-demo';

export function getLocaleId() {
    return localStorage.getItem('content-lang') ? localStorage.getItem('content-lang')?.replace(/"/g, '') : DEFAULT_LOCALE;
}

export function getLanguageDropDown() {
    return [
        {
            label: 'US English',
            value: Language.EN_US
        },
        {
            label: 'UK English',
            value: Language.EN_GB
        },
        {
            label: 'Canadian French',
            value: Language.FR_CA
        }
    ];
}
@NgModule({
    imports: [CustomerPortalUiModule],
    providers: [
        { provide: CONSUMER_PORTAL_APP_CONFIG, useValue: environment },
        { provide: CONSUMER_LOGIN_APP_CONFIG, useValue: environment },
        { provide: DEMO_ENV_CONFIG, useValue: environment },
        {
            provide: CPEnvironment,
            useValue: environment
        },
        {
            provide: LOCALE_ID,
            useFactory: getLocaleId
        },
        {
            provide: CONSUMER_PORTAL_APP_LANG,
            useFactory: getLanguageDropDown
        }
    ]
})
export class AppModule implements DoBootstrap {
    constructor(private readonly injector: Injector) {}

    ngDoBootstrap(): void {
        const entryPoint = createCustomElement(CustomerPortalComponent, {
            injector: this.injector
        });
        customElements.get('por-consumer-portal') || customElements.define('por-consumer-portal', entryPoint);
    }
}
