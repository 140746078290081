import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocState, DocumentData, documentsFeatureKey } from '../reducers/documents.reducer';

// Select the document feature state
export const selectDocumentState = createFeatureSelector<DocState>(documentsFeatureKey);

// Selector to get all document data
export const selectAllDocumentData = createSelector(selectDocumentState, (state: DocState) => state.documentData);

// Selector to get document data by type
export const selectDocumentDataByType = (docType: string) => createSelector(selectAllDocumentData, (documents: DocumentData[]) => documents.filter(doc => doc.docType === docType));
