/* eslint-disable no-console */
import { Injectable, isDevMode } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    logInfo(message: unknown, design?: string) {
        if (isDevMode()) {
            console.log(message, design);
        }
    }

    logError(message: unknown, design?: string, showInProd?: boolean): void {
        if (isDevMode() || showInProd) {
            console.error(message, design);
        }
    }

    alertDevError(error: unknown) {
        if (isDevMode() && error) {
            alert(JSON.stringify(error));
        }
    }

    logWarning(message: unknown, design?: string) {
        if (isDevMode()) {
            console.warn(message, design);
        }
    }

    logAssert(message: unknown, design?: string) {
        if (isDevMode()) {
            console.assert(message, design);
        }
    }
}
