<div id="apx" class="customer-portal-wrappper-cp">
    <por-app-loader *ngIf="showloader"></por-app-loader>
    <ng-container *ngIf="configService.cpInputs$ | async as cpConfig">
        <ng-container *ngIf="componentRedirection$ | async as redirection">
            <ng-container [ngSwitch]="redirection.toLoad">
                <ng-container *ngSwitchCase="'Subdomain'">
                    <por-subdomain-redirection [redirectionConfig]="cpConfig" (setActiveComponent)="setActiveComponent($event)"></por-subdomain-redirection>
                </ng-container>
                <ng-container *ngSwitchCase="'RoutingError'">
                    <div class="por consumer-portal-error" *ngIf="!versionToggleService.isQuickLinkVersion()">
                        <div class="error-con">
                            <h3>{{ errorMessage }}</h3>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'ConsumerPortal'">
                    <ng-container *ngIf="isRedirectionConfigSet$ | async">
                        <ng-container [ngSwitch]="states$ | async">
                            <ng-container *ngSwitchCase="'None'">
                                <div>
                                    {{ 'No View Found' }}
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'login'">
                                <por-consumer-login
                                    [config]="configService.loginConfig"
                                    (loginSuccess)="onLoginSuccess($event)"
                                    [alertMessage]="alertMessage"
                                    [quickLinkUrl]="quickLinkUrl"></por-consumer-login>
                            </ng-container>
                            <ng-container *ngSwitchCase="'embeded-login'">
                                <div class="redirection-wrapper por">
                                    <por-busy-indicator [message]="errorMessage ? errorMessage : ('Logging in' | translate)"></por-busy-indicator>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'redirected'">
                                <por-consumer-redirection
                                    [redirectionConfig]="redirectionConfig"
                                    (handleRedirection)="handleRedirection($event)"
                                    (getCustomerId)="getCustomerId($event)"
                                    (logout)="logout()"
                                    (loaded)="loadConsumerPortal($event)"></por-consumer-redirection>
                            </ng-container>
                            <ng-container *ngSwitchCase="'portal'">
                                <ng-container [ngSwitch]="versionComponentRedirection$ | async">
                                    <ng-container *ngSwitchCase="'BasicConsumerPortal'">
                                        <por-basic-consumer-portal [config]="cpConfig | stringify" (logout)="logout()"></por-basic-consumer-portal>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'QuickLink'">
                                        <por-quick-link
                                            [config]="cpConfig | stringify"
                                            (logout)="logout()"
                                            (returnToLoginFromQuickLink)="returnToLoginFromQuickLink($event)"
                                            (returnQuickLinkFromLogin)="returnQuickLinkFromLogin($event)"></por-quick-link>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'ConsumerPortalError'">
                                        <div class="por consumer-portal-error" *ngIf="!versionToggleService.isQuickLinkVersion()">
                                            <div class="error-con">
                                                <h3>{{ errorMessage }}</h3>
                                                <por-base-button (onclick)="logout()">{{ 'Logout' | translate }}</por-base-button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'ConsumerPortal'">
                                        <ng-container [ngSwitch]="appFacadeService.getActiveTab() | async">
                                            <ng-container>
                                                <por-header *ngIf="reInit" [config]="cpConfig | stringify" [customer]="customer$ | async" (logout)="logout()"></por-header>
                                            </ng-container>
                                            <div class="sections-wrapper">
                                                <ng-container *ngSwitchDefault>
                                                    <por-summary [config]="cpConfig | stringify" *ngIf="reInit" (openContractsWithFilter)="openContractsWithFilter($event)"></por-summary>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'Contracts'">
                                                    <por-contracts
                                                        [customer]="customer$ | async"
                                                        [config]="cpConfig | stringify"
                                                        [initialStatusFilters]="contractStatusFilters"
                                                        (clearInitFilters)="clearInitFilters()"
                                                        *ngIf="reInit"></por-contracts>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'ItemsOut'">
                                                    <por-items-out [customerId]="customerId" [config]="cpConfig | stringify" *ngIf="reInit"></por-items-out>
                                                </ng-container>

                                                <ng-container>
                                                    <ng-container *ngSwitchCase="'Invoices'">
                                                        <por-invoices [config]="cpConfig | stringify" *ngIf="reInit"></por-invoices>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'Tracking'">
                                                    <por-tracking [config]="cpConfig | stringify" *ngIf="reInit"></por-tracking>
                                                </ng-container>
                                            </div>
                                            <ng-container>
                                                <por-footer [config]="cpConfig | stringify"></por-footer>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
