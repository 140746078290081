import { Subscription } from 'rxjs';
import { OnDestroy, Output, Component, OnInit, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ConsumerPortalConfig } from '../../../../models/consumer-portal-config';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsToLoad } from '../../../../enums/components-to-load.enum';
import { Navigation } from '../../../../models/navigation.model';
import { Menu as AdminMenu } from '../../../../models/consumer-portal-admin-config';
import { AppFacadeService } from '../../../../services/app-facade.service';
import { AppMediatorService } from '../../../../services/app-mediator.service';
type Menus = Partial<AdminMenu> | undefined | null;
@Component({
    selector: 'por-admin-page-sidebar',
    templateUrl: './admin-page-sidebar.component.html',
    styleUrls: ['./admin-page-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPageSidebarComponent implements OnInit, OnDestroy {
    @Output() readonly getActiveTab = new EventEmitter();

    inputs: ConsumerPortalConfig | undefined;
    menu: Menus;
    navigations: Navigation[] = [];
    subscriptions: Subscription[] = [];

    constructor(private readonly translateService: TranslateService, readonly appFacadeService: AppFacadeService, private readonly appMediatorService: AppMediatorService) {}

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        [
            {
                tab: ComponentsToLoad.AdminOverview,
                text: this.translateService.instant('AdminNavOverview'),
                params: []
            },
            {
                tab: ComponentsToLoad.AdminDifference,
                text: this.translateService.instant('AdminNavDiff'),
                params: []
            },
            this.appFacadeService.versionToggleService.isBasicVersion() && {
                tab: ComponentsToLoad.AdminSetup,
                text: this.translateService.instant('AdminNavBasicSetup')
            },
            this.appFacadeService.versionToggleService.isAdvanceVersion() && {
                tab: ComponentsToLoad.AdminSetup,
                text: this.translateService.instant('AdminNavAdvSetup'),
                params: []
            },
            {
                tab: ComponentsToLoad.AdminAccountList,
                text: this.translateService.instant('AdminNavAccountsList'),
                params: []
            }

            // {
            //     tab: ComponentsToLoad.AdminWebPay,
            //     text: this.translateService.instant('AdminNavQuickLink'),
            //     params: []
            // }
        ].map(nav => {
            if (nav) {
                this.navigations.push(nav);
            }
        });

        this.appFacadeService.setActiveTab(ComponentsToLoad.AdminOverview);
    }

    toggleTab(tab: ComponentsToLoad | undefined) {
        if (tab) {
            this.appFacadeService.setActiveTab(tab);
            this.getActiveTab.emit(tab);
        }
    }
}
