import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { ContractCP } from '../../models/contract-model';
import { RowLabelValue } from '../../models/row-label-value-model';
import { FeatureToggleService } from '../../services/feature-toggle.service';

@Component({
    selector: 'por-contract-full-width-row',
    templateUrl: './contract-full-width-row.component.html',
    styleUrls: ['./contract-full-width-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractFullWidthRowComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams;

    constructor(@Inject(LOCALE_ID) locale: string, private readonly date: DatePipe, private readonly featureService: FeatureToggleService) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }
    contract!: ContractCP;
    contractKV: RowLabelValue[] = [];
    dateFormat = '';
    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.contract = { ...params.data };
        this.contractKV = [
            {
                label: 'contractId',
                value: this.contract?.contractId
            },
            {
                label: 'contract',
                value: this.contract?.contractName
            },
            {
                label: 'status',
                value: this.contract?.status
            },
            {
                label: 'startDate',
                value: this.date.transform(this.contract?.openDate, this.dateFormat)
            },
            {
                label: 'closeDate',
                value: this.date.transform(this.contract?.closeDate, this.dateFormat)
            },
            {
                label: 'purchaseOrder',
                value: this.contract?.purchaseOrder
            },
            {
                label: 'location',
                value: this.contract?.info
            }
        ];

        if (this.featureService.isAvailable('contractGridJobColumnAvailable')) {
            this.contractKV.splice(4, 0, {
                label: 'jobNumber',
                value: this.contract?.jobNumber
            });
        }
    }
    refresh() {
        return true;
    }

    loadContractDetail() {
        this.params.context?.componentParent.loadContractDetailModal(this.contract?.contractId);
    }
}
