import { Injectable } from '@angular/core';
import { EnabledFeatures, Features } from '../models/consumer-portal-config';
import { consumerPortalDefaults } from '../../input-config/consumer-portal-config';
import { isArray } from 'lodash';
import { ContractStatus } from '../types/contract.type';
import { QuickLinkService } from './quicklink.service';
import { OrganizationType } from '../models/organization-type';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureToggleService {
    private enabledFeatures: RemapFeatures = {
        invoices: [],
        logOff: [],
        consumerLogin: [],
        multipleCustomerSelection: [],
        payFullAmount: [],
        textSearchMultipleFilters: [],
        pdfDownloadFeature: [],
        contractGridJobColumnAvailable: [],
        canSeeBalance: [],
        showDetailDocuments: [],
        checkPaymentLicense: [],
        contractCancel: [],
        contractEdit: [],
        capturePaymentFeature: [],
        showHeaderSections: [],
        canHaveSelectedItemsActions: [],
        productDetailFeature: [],
        canHaveProductsKeyDocumentsView: [],
        canHaveQuickLinkLoginButton: [],
        displayInspectionDocument: [],
        displayServiceCertificate: [],
        secureUrlLoginRequired: [],
        callOffAbility: [],
        trackingTab: [],
        requestService: []
    };

    private enableFeatures: EnabledFeatures = {};
    /**
     * organization type, we needed to check org type when user logged out
     */
    private organizationType!: string;
    constructor(private readonly localStorageService: LocalStorageService, private readonly quickLinkService: QuickLinkService) {
        // Setting the hard-coded default features
        this.remapFeatures(consumerPortalDefaults.enabledFeatures ?? {});
    }

    // Get the features
    get features(): RemapFeatures {
        return this.enabledFeatures;
    }

    isAvailable(feature: string, options?: { subFeature: string; state: string; ignoreFeatureCheck?: boolean }): boolean {
        const orgType = this.getType();
        if ((!options?.ignoreFeatureCheck && this.features[feature as RemapFeaturesTypeKey]?.includes(orgType)) || (this.quickLinkService.consumerQuickLinkVersion && feature === 'logOff')) {
            return false;
        }
        if (options !== null && options) {
            return this.isSubFeatureAvailable(options.subFeature, options.state);
        }
        return true;
    }

    private isSubFeatureAvailable(subFeature: string, state: string): boolean {
        const orgType: RmsTypeKey = this.getType() as RmsTypeKey;
        const subFeatureKey: FeatureTypeKey = subFeature as FeatureTypeKey;
        if (this.enableFeatures && orgType && subFeatureKey && (orgType as string) in this.enableFeatures) {
            const keys: Features | undefined = this.enableFeatures[orgType];
            if (keys && subFeatureKey in keys && this.enableFeatures[orgType] !== undefined) {
                const subFeatureStatus: boolean | ContractStatus[] = keys[subFeatureKey] ?? [];
                if (isArray(subFeatureStatus)) {
                    return subFeatureStatus?.some(f => f.includes(state));
                }
            }
        }
        return false;
    }

    getType(): string {
        return this.localStorageService.getOrganizationType ? this.localStorageService.getOrganizationType : this.organizationType ?? this.localStorageService?.getCurrentUser?.organizationType;
    }

    setType(organizationType: string | undefined): void {
        if (organizationType !== undefined) {
            this.organizationType = organizationType;
        }
    }

    setFeatures(features: EnabledFeatures | undefined, organizationType: string | undefined): void {
        this.enableFeatures = features ?? {};
        if (features !== undefined) {
            this.remapFeatures(features);
        }
        if (organizationType !== undefined) {
            this.organizationType = organizationType;
        }
    }

    isSyrinx(): boolean {
        return this.getType() === OrganizationType.SYRINX;
    }

    private remapFeatures(features: EnabledFeatures): void {
        this.enabledFeatures = {
            invoices: [],
            logOff: [],
            consumerLogin: [],
            multipleCustomerSelection: [],
            payFullAmount: [],
            textSearchMultipleFilters: [],
            pdfDownloadFeature: [],
            contractGridJobColumnAvailable: [],
            checkPaymentLicense: [],
            contractCancel: [],
            contractEdit: [],
            capturePaymentFeature: [],
            showHeaderSections: [],
            canSeeBalance: [],
            showDetailDocuments: [],
            canHaveSelectedItemsActions: [],
            productDetailFeature: [],
            canHaveProductsKeyDocumentsView: [],
            canHaveQuickLinkLoginButton: [],
            displayInspectionDocument: [],
            displayServiceCertificate: [],
            secureUrlLoginRequired: [],
            callOffAbility: [],
            trackingTab: [],
            requestService: []
        };
        Object.entries(features).map(([featureType, value]) => {
            Object.keys(value).forEach(pfk => {
                if (pfk in this.enabledFeatures && value[pfk] === false) {
                    if (this.enabledFeatures[pfk as RemapFeaturesTypeKey].some(f => f === featureType) === false) {
                        this.enabledFeatures[pfk as RemapFeaturesTypeKey].push(featureType);
                    }
                }
            });
        });
    }

    mergeFeatures(defaults: EnabledFeatures | undefined, inputs: EnabledFeatures | undefined): EnabledFeatures {
        const mergedObj: EnabledFeatures = {};
        if (defaults !== undefined && inputs !== undefined) {
            for (const key in defaults) {
                if (Object.prototype.hasOwnProperty.call(defaults, key) && Object.prototype.hasOwnProperty.call(inputs, key)) {
                    mergedObj[key as RmsTypeKey] = { ...defaults[key as RmsTypeKey], ...inputs[key as RmsTypeKey] };
                } else {
                    mergedObj[key as RmsTypeKey] = { ...defaults[key as RmsTypeKey] };
                }
            }
        }
        return mergedObj;
    }
}

type RemapFeaturesTypeKey = keyof RemapFeatures;
type FeatureTypeKey = keyof Features;
type RmsTypeKey = keyof EnabledFeatures;

interface RemapFeatures {
    invoices: string[];
    logOff: string[];
    consumerLogin: string[];
    multipleCustomerSelection: string[];
    payFullAmount: string[];
    textSearchMultipleFilters: string[];
    pdfDownloadFeature: string[];
    contractGridJobColumnAvailable: string[];
    checkPaymentLicense: string[];
    contractEdit: string[];
    contractCancel: string[];
    capturePaymentFeature: string[];
    showHeaderSections: string[];
    canSeeBalance: string[];
    showDetailDocuments: string[];
    canHaveSelectedItemsActions: string[];
    productDetailFeature: string[];
    canHaveProductsKeyDocumentsView: string[];
    canHaveQuickLinkLoginButton: string[];
    displayInspectionDocument: string[];
    displayServiceCertificate: string[];
    secureUrlLoginRequired: string[];
    callOffAbility: string[];
    trackingTab: string[];
    requestService: string[];
}
