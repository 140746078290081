<ng-container>
    <div class="rental-requests-modal">
        <h1 class="theme-heading">{{ 'PaymentHistoryForContract' | translate }} # {{contractDetail?.[0]?.Id}}</h1>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <th>{{ 'PaidOnDate' | translate }}</th>
                    <th>{{ 'TotalPaid' | translate }}</th>
                    <th>{{ 'ReferenceNumber' | translate }}</th>
                    <th *ngIf="isPaymentMethodAvailable()">{{ 'PaymentMethod' | translate }}</th>
                    <th>{{ 'Processed' | translate }}</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let receivedPayment of contractDetail?.[0]?.Payment">
                        <tr>
                            <td class="text-center">{{ receivedPayment.PaidDateTime | formatDate }}</td>
                            <td class="text-center">{{ receivedPayment.TotalPaid | formatCurrency }}</td>
                            <td class="text-center">{{ receivedPayment.ReferenceNumber }}</td>
                            <td class="text-center" *ngIf="isPaymentMethodAvailable()">{{ getPaymentMethod(receivedPayment.PaymentMethod) }}</td>
                            <td class="text-center">
                                <ng-container [ngSwitch]="receivedPayment.Consumed">
                                    <ng-container *ngSwitchCase="consumed.Imported">{{ 'Imported' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="consumed.Posted">{{ 'Posted' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="consumed.Error">{{ 'Error' | translate }}</ng-container>
                                    <ng-container *ngSwitchDefault>{{ 'Waiting' | translate }}</ng-container>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
